<template>
    <v-layout class="map-wrap" align-center justify-center>
        <div ref="map" class="map">
            <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/map_${$gameData.mapData.id}.png`"
                 :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
            />

            <v-layout class="image-map"
				justify-center align-center
				v-for="(item, index) in $gameData.mapData.links"
			    v-if="$gameData.mapData.id <= 3 || ($userData.stage >= item.stage && (!item.condition || $userData.hintItem[item.condition]) && (!item.related || !$userData.hintItem[item.related]))"
				:key="index"
				:style="`left:${item.left/1280*100}%;top:${item.top/720*100}%;width:${item.width/1280*100}%;height:${item.height/720*100}%;`"
				@click="moveTo(item.target, item.hint)"
			>
	            <img v-if="item.asset"
	                 class="asset"
	                 :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/${item.asset.filename}`"
	                 :style="`bottom:10%;width:${item.asset.width*$gameData.scale}px;height:${item.asset.height*$gameData.scale}px;`" />

				<span>
					<img v-if="item.icon && item.icon.endsWith('icon_hint.png')" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/${item.icon}`"
					     :style="`width: ${50*vw}px`"
					     />
					<img v-if="item.icon && !item.icon.endsWith('icon_hint.png')" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/${item.icon}`"
					     :style="`width: ${135*vw}px`"
					/>
				</span>
	            <span class="new-icon"
	                  v-if="item.icon && isNew(item) && $gameData.hints[item.hint].type === 'P'"
	                  style="top: 20%; left: 16%;"
	            >
					<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_hint_new.png"
					     :style="{width: `${35*$gameData.scale}px`}"
					/>
	            </span>
	            <span class="new-icon"
	                  v-if="item.icon && isNew(item) && $gameData.hints[item.hint].type === 'T'"
	                  style="top: 28%;"
	            >
					<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_hint_new.png"
					     :style="{width: `${35*$gameData.scale}px`}"
					/>
	            </span>

	            <span class="new-icon"
	                  v-if="!item.icon && isPlaceNew(item)"
	                  style="top: 16%; left: 57%;"
	            >
					<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_hint_new.png"
					     :style="{width: `${35*$gameData.scale}px`}"
					/>
	            </span>

			</v-layout>
			<div
				class="name-wrap"
				v-resize-text="{ratio:0.5, minFontSize: 10}"
				v-for="(item, index) in $gameData.mapData.usernames"
				:style="`left:${item.left/1280*100}%;top:${item.top/720*100}%;width:${item.width/1280*100}%;height:${item.height/720*100}%;`"
				>
				{{ $gameData.names[item.username] }}
			</div>
        </div>
    </v-layout>
</template>

<script>
	export default {
		data() {
			return {
			}
		},

		computed: {
			vw: {
				get() {
					if (this.$gameData.viewWidth) {
						return this.$gameData.viewWidth / 1280
					} else {
						return $('.map > img').width() / 1280
					}
				},
				set() {}
			},
			vh: {
				get() {
					if (this.$gameData.viewHeight) {
						return this.$gameData.viewHeight / 720
					} else {
						return $('.map > img').height() / 720
					}
				},
				set() {}
			},
			isNew() {
				return item => {
					// if (!this.$userData.hint[item.hint] && this.$gameData.hints[item.hint]) {
					// 	return true
					// }

					let isNew = false
					this.$gameData.hints[item.hint].items.forEach(v => {
						if (v.stage <= this.$userData.stage) {
							if (!this.$userData.hintItem[v.id] && (!v.condition || this.$userData.hintItem[v.condition])) {
								isNew = true
							}
						}
					})

					return isNew

				}
			},
			isPlaceNew() {
				return item => {
					// if (!this.$userData.hint[item.hint] && this.$gameData.hints[item.hint]) {
					// 	return true
					// }

					let isNew = false

					this.$gameData.maps[item.target].links.forEach(map => {
						this.$gameData.hints[map.hint].items.forEach(v => {
							if (v.stage <= this.$userData.stage) {
								if (!this.$userData.hintItem[v.id] && (!v.condition || this.$userData.hintItem[v.condition])) {
									isNew = true
								}
							}
						})

					})

					return isNew

				}
			},
		},

		beforeCreate() {
		},

		watch: {
		},

		mounted() {

			const self = this

			$(window).off('resize', this.onResize).on('resize', this.onResize).resize()
		},
		methods: {
			onResize() {
				this.vw = this.$gameData.viewWidth / 1280
				this.vh = this.$gameData.viewHeight / 720
			},
			playAudio(id) {
				this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
			},
			moveTo(target, hint) {
				const self = this
				this.playAudio(1)

				$('.map-wrap').fadeOut(200, _ => {
					if (target) {

						const mapData = self.$gameData.maps[target]

						if (mapData.mission_value) {
							if (!this.$userData.map[mapData.id]) {
								this.$eventBus.$emit('CHECK_MISSION', mapData.mission_value)
							}
						}

						this.$set(this.$userData.map, target, true)

						switch (target) {
							case 8: this.playAudio(6); break; // VIP라운지
							case 6: this.playAudio(7); break; // 객장
							case 4: this.playAudio(8); break; // 개인종합창구
							case 5: this.playAudio(9); break; // 기업종합창구
							case 7: this.playAudio(10); break; // 직원휴게실
							case 9: this.playAudio(11); break; // 사무실
							case 11: this.playAudio(12); break; // 복도
							case 12: this.playAudio(13); break; // 회의실
							case 10: this.playAudio(14); break; // 엘리베이터
							case 13: this.playAudio(15); break; // 식당
							case 14: this.playAudio(16); break; // 로비
						}

						self.$emit('change', mapData)
						$('.map-wrap').fadeIn(200)
					} else if (hint) {
						self.$eventBus.$emit('OPEN_HINT', hint)

						this.$set(this.$userData.hint, hint, true)
					}
				})

			},
		}
	}
</script>

<style lang="scss" scoped>
    .map-wrap {
        width: 100% !important;
        height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;

        .map {
            display: inline-block;
            margin: 0 ;
            position: relative;
			line-height: 0;

	        .asset {
		        position: absolute;
		        z-index: 100;
	        }

            .image-map {
                /*background: rgba(0,0,0,0.3);*/
                position: absolute;
				z-index: 101;

				img {
					/*display: none;*/
					height: auto;
				}

	            .new-icon {
		            position: absolute;
	            }
            }

			.name-wrap {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #60584C;
			}
        }
    }
</style>
