<template>
	<v-sheet class="popup-layout" width="100%" height="100%">
		<v-sheet class="popup-wrap" width="100%" height="100%">
			<div class="popup-title"
			     :style="{fontSize: `${40*$gameData.scale}px`, height: `${60*$gameData.scale}px`}"
			>
				단서 노트
				<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="v => {$emit('close'); playAudio(1)}" />
			</div>
			<div class="body">
				<v-layout justify-center align-center column>
					<div class="persons"
					>
						<div class="person" v-for="usernumber in 3" @click="detail(usernumber)">
							<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user${usernumber}.png`" />
							<div class="name">{{ names[`username${usernumber}`] }}</div>
						</div>
					</div>
					<div class="persons"
					>
						<div class="person" v-for="usernumber in 6" v-if="usernumber >= 4" @click="detail(usernumber)">
							<div v-if="$userData.stage >= 2">
								<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user${usernumber}.png`" />
								<div class="name">{{ names[`username${usernumber}`] }}</div>
							</div>
							<div v-else>
								<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user_unknown.png" />
							</div>
						</div>
						<div v-if="$userData.stage < 2" class="diabled">
							미션 11개 달성 시 공개
						</div>
					</div>
					<div class="persons"
					>
						<div class="person" v-for="usernumber in 8" v-if="usernumber >= 7" @click="detail(usernumber)">
							<div v-if="$userData.stage == 3">
								<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user${usernumber}.png`" />
								<div class="name">{{ names[`username${usernumber}`] }}</div>
							</div>
							<div v-else>
								<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user_unknown.png" />
							</div>
						</div>
						<div v-if="$userData.stage < 3" class="diabled">
							미션 15개 달성 시 공개
						</div>
					</div>
				</v-layout>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		props: [
			'no'
		],
		computed: {
			names() {
				return this.$gameData.names
			}
		},
		mounted() {
		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			detail(no) {
				this.playAudio(2)
				this.$eventBus.$emit('NOTE_DETAIL', no)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.popup-layout {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3%;
		background-color: #3d3d3d;
		z-index: 500;

		.popup-wrap {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			border: solid 5px #febd36;
			background-color: #ffe5c0;
			display: flex;
			flex-direction: column;

			.popup-title {
				height: 45px;
				background-color: #febd36;
				font-weight: 400;
				font-family: KBFGDisplayB;
				font-size: 25px;
				color: #f9f9f9;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					height: 80%;
					position: absolute;
					right: 1%;
					top: 1%;
				}
			}

			.body {
				padding: 1%;
				height: 100%;
				overflow-y: scroll;
				position: relative;

				.persons {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					position: relative;

					.person {
						width: 20%;
						display: inline-block;
						margin: 1% 1%;
						position: relative;

						.name {
							position: absolute;
							bottom: 6%;
							width: 100%;
							text-align: center;
							color: #ffffff;
							font-size: 20px;
							font-weight: 400;
							font-family: KBFGDisplayB;
						}
					}

					.diabled {
						position: absolute;
						width: 55%;
						height: 75%;
						border-radius: 5px;
						background-color: rgba(24,24,24,0.8);
						color: #ffffff;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}

		}
	}
</style>
<style>
	img {
		max-width: 100%;
	}
</style>
