import Vue from 'vue';

export default {
	install(Vue, store) {

		const gameData = {
			ended: false,
			viewWidth: 0,
			viewHeight: 0,
			orientation: '',
			scale: 1,
			names: [],
			characters: [],
			strengths: [],
			maps: {},
			mapData: {id:1},
			hints: [],
			hint: {},
			hintItem: null,
			missions: {},
			positions: {
				1: '대리',
				2: '대리',
				3: '대리',
				4: '대리',
				5: '대리',
				6: '과장',
				7: '과장',
				8: '차장',
			},
		}

		const userData = {
			stage: 1,
			map: {},                // 장소 방문 기록
			hint: {},               // 단서 열람 기록 (ex. G과장)
			hintItem: {},           // 단서 상세 열람 기록 (ex. [username2에 대해 물어보기])
			characterHints: {},     // 인물 단서별 열람 여부
			noteStrengths: {},      // 단서노트의 인물별 예상강점
			strengths: [],          // 인물별 강점 매칭 기록
			strengthCompleted: {},  // 인물별 강점 매칭 성공
			matchCounts: {},        // 인물별 강점매칭 시도 횟수
			points: 60,             // 획득포인트
			adminPoints: 0,         // 관리자 지급포인트
			usedHint: {},           // 강점매칭 힌트 사용 횟수
			valueSum: 0,            // 미션 완료 여부 확인을 위한 값 (BIT연산을 위한 단서별 value의 합)
			missionOpened: {},      // 미션별 오픈여부
			missionCompleted: {},   // 미션 완료여부
			// missionSum: 0,
			traineeStrengths: 0,    // 교육생의 최종선택 강점 값 (BIT연산을 위한 강점별 value의 합)
		}

		Vue.prototype.$gameData = Vue.observable(gameData)

		Vue.prototype.$userData = Vue.observable(userData)

	}
};
