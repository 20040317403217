<template>
    <v-layout column class="names-main justify-start align-center" fill-height>
        <div class="message-wrap">
            <div class="message"
                 :style="{fontSize: `${25*$gameData.scale}px`}"
            >
                <p>
                    아래 <span class="highlight">8개의 질문</span>에 해당하는 사람의<br class="portrait"/> 성을 제외한 이름만 입력해주세요.<br />
                    <span class="hint"
                          :style="{fontSize: `${23*$gameData.scale}px`}"
                    >(영문, 공란, 특수 문자, 중복 내용 입력 불가 /<br class="portrait" /> 한글 2 ~ 3 글자로 입력)</span><br />
                    <span class="highlight small"
                          :style="{fontSize: `${23*$gameData.scale}px`}"
                    >ex) 홍길동(X) / 길동(0)</span>
                </p>
            </div>
        </div>
        <div class="questions">
            <v-row class="item ma-2" v-for="(question, index) in questions" :key="index" :class="names[index] && names[index].length >= 2 ? 'active' : ''"
            >
                <v-col cols="9" class="d-flex justify-start align-center question"
                       :style="{fontSize: `${24*$gameData.scale}px`}"
                >{{question}}</v-col>
                <v-col cols="3" class="pa-0">
                    <input type="text"
                           v-model="names[index]"
                           maxlength="3"
                    />
                </v-col>
            </v-row>
        </div>
        <div>
        <v-img class="btn-select mt-2 mb-10" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength_select_${isCompleted ? 'on' : 'off'}.png`" width="100" @click="v => {isCompleted && onCompleted(); playAudio(1); }" contain />
        </div>
        <div class="preloader">
            <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength_select_on.png" />
        </div>
    </v-layout>
</template>

<script>
    export default {

	    data() {
		    return {
			    questions: [
                    '1. 나랑 가장 친한 친구 이름은?',
                    '2. 내 주변에서 가장 눈치가 빠른 사람 이름은?',
                    '3. 내 주변에서 가장 순수한 사람 이름은?',
                    '4. 내 주변에서 노래를 가장 잘 하는 사람 이름은?',
                    '5. 내 주변에서 가장 아재개그를 많이하는 사람은?',
                    '6. 내 주변에서 가장 도전정신이 강한 사람 이름은?',
                    '7. 내 주변에서 가장 맛집을 많이 알고 있는 사람 이름은?',
                    '8. 내 주변 가장 옷을 잘입는 사람(패셔니스타)은?',
                ],
                names: [
                	'',
	                '',
	                '',
	                '',
	                '',
	                '',
	                '',
	                '',
                ],
		    }
	    },

        computed: {
	    	isCompleted() {
	    		let completed = true
	    		this.names.forEach(name => {
	    			if (!name || name.length < 2) completed = false
                })

                return completed
            }
        },

        mounted() {

	        $('input[type=text]').keyup(e => {
	        	const target = e.currentTarget
                const index = $('input[type=text]').index(target)
		        const pattern = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
		        this.$set(this.names, index, target.value.replace(pattern, ''));
            }).change(e => {
		        const target = e.currentTarget
                const index = $('input[type=text]').index(target)

                if (!target.value) return

                let duplicated = false
                this.names.forEach((name, idx) => {
                	if (!name || index === idx) return
                	if (target.value === name) {
                		duplicated = true
                        // this.$set(this.names, index, '')
	                }
                })

                if (duplicated) {
                    this.$set(this.names, index, '')

                    this.$store.commit('showAlert', {
		                message: '영어, 공란, 특수문자, 중복 내용\n' +
                                '입력 불가 합니다.'
	                })

                    target.focus()
                }
            })

            $(window).scrollTop(0)
        },

	    watch: {
        },


        beforeRouteEnter(to, from, next) {

            next(vm => {
                vm.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/names`).then(response => {
                    if (response.data.code === '0000') {
                    	if (!response.data.result) return
                        const names = []
                    	Object.keys(response.data.result).forEach(key => {
                    		names.push(response.data.result[key])
                        })

                        vm.names = names
                    }
                })
                const params = {
                    progress: vm.$route.path,
                }

                vm.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/progress`, params)
            })
        },

	    methods: {
	    	onCompleted() {
	    		const params = {
	    			names: this.names
                }
			    this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/names`, params).then(response => {
				    if (response.data.code === '0000') {
					    this.$router.push('/play/video')
				    } else {
					    this.$store.commit('showSnackbar',{
						    message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
						    color: 'error',
						    duration: 3000
					    });
				    }
			    })
				    .catch(error => {
					    console.log(error)
				    })
		    },

            playAudio(id) {
                this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
            }
	    },
    }
</script>

<style lang="scss" scoped>
    .landscape {
        display: inherit;
    }
    .portrait {
        display: none;
    }
    @media screen and (orientation:portrait) {
        .landscape {
            display: none;
        }
        .portrait {
            display: inherit;
        }

        .questions {
	        .item {
		        margin-left: 8% !important;
                margin-right: 8% !important;
                flex-direction: column;
                text-align: center;
                align-items: center;

                .col-3 {
                    max-width: 100%;
                }

                input {
                    margin: 12px 0;
                    width: 43vw !important;
                }
            }
        }

        .buttongroup {
            width: 350px;
        }

        .buttons {
            .button {
                width: 130px !important;
            }
        }

        .btn-select {
            width: 120px !important;
        }
    }

    .names-main {
        text-align: center;
    }
    .message-wrap {
        width: 100%;
        padding: 18px 0 0 0;
        background-color: #DBD3CC;

        .message {
            margin: 0 auto;
            font-size: 20px;
            color: #787067;
            line-height: 1.3;
            font-weight: 400;
            font-family: KBFGDisplayB;

            .hint {
                font-size: 16px;
            }

            .highlight {
                font-weight: 400;
                font-family: KBFGDisplayB;
                background-color: #FEBD36;
                padding: 1px;

                &.small {
                }
            }
        }
    }

    .questions {
        text-align: left;
        padding-bottom: 10px;

        .item {
            background-color: #C4BCB5;
            padding: 7px 20px;
            border-radius: 5px;

            .question {
                padding: 0;
                color: #787067;
                word-break: keep-all;
            }

            input {
                background-color: #ffffff;
                border: 0;
                border-radius: 5px;
                width: 100%;
                padding: 2px;
                text-align: center;
                font-size: 17px;
                color: #787067;
                font-weight: 400;
                font-family: KBFGDisplayB;
            }

            &.active {
                background-color: #FEBD36;

                .question {
                    color: #ffffff;
                }
            }
        }
    }
</style>
