<template>
    <v-layout class="login-layout" align-center justify-center fill-height>
        <v-card
                class="login-form"
                elevation="0"
                color="transparent"
        >
            <v-card-title class="justify-center pa-0">
                <div class="logo-title">
                    <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/login_logo.png" width="100%" />
                </div>
            </v-card-title>
            <v-card-text class="pa-0 mt-5">
                <v-form ref="form"
                        class="form"
                        v-model="valid"
                        lazy-validation>
                    <v-text-field ref="email"
                                  label="아이디"
                                  type="email"
                                  dense
                                  solo
                                  flat
                                  hide-details
                                  class="input-field mt-2"
                                  color="#A59D95"
                                  v-model="email"
                    />
                    <v-text-field ref="passwd"
                                  label="패스워드"
                                  type="password"
                                  dense
                                  solo
                                  flat
                                  hide-details
                                  class="input-field mt-2"
                                  color="#A59D95"
                                  v-model="passwd"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions class="pa-0 mt-4">
                <v-img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_login.png" width="187" @click="login" />
            </v-card-actions>
        </v-card>
<!--        <div class="a2hs-wrap" :class="device">-->
<!--            <div v-if="device==='ios'" class="a2hs ios">-->
<!--                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon-192x192.png" />-->
<!--                <div class="message">-->
<!--                    <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_ios_share.png" align="top" />를 클릭 후 [홈 화면에 추가]를 터치해 앱으로 설치하여 플레이 해주시기 바랍니다.-->
<!--                </div>-->
<!--            </div>-->
<!--            <div v-if="device==='android'" class="a2hs android">-->
<!--                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon-192x192.png" />-->
<!--                <div class="message">-->
<!--	                여기를 눌러 KB C.S.I 앱을 설치 후 플레이 해주시기 바랍니다.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="landing">
            <div v-if="device === 'ios'" class="ios">
                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/landing_ios.png" />
            </div>
            <div v-if="device === 'android'" class="android">
                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/landing_android.png" />
                <div>
                    <img class="btn-install" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_install.png" />
                </div>
            </div>
        </div>
    </v-layout>
</template>

<script>
    export default {
	    name: 'Login',

	    components: {
	    },

	    data() {
		    return {
                device: 'android',
			    valid: true,
			    email: '',
			    passwd: '',

			    emailRules: [
				    (v) => !!v || '이메일주소를 입력하세요.',
				    (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || '이메일 주소형식이 정확하지 않습니다.'
			    ],
		    }
	    },

	    beforeRouteEnter(to, from, next) {
		    next(vm => {
			    vm.$store.commit('hideAppBar')
		    })
	    },
        beforeCreate() {
            if (this.$cookie.get('token')) {
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/verifyToken`).then(response => {
                    if (response.data.code === '0000') {
                    	// console.log(this.$userData)
                    	if (response.data.result.last_progress) {
                            this.$cookie.set('id', response.data.result.id, 30)
                            this.$cookie.set('training_id', response.data.result.training_id, 30)
                            this.$router.replace(response.data.result.last_progress)
                        } else {
                            this.$router.replace('/intro')
                        }
                    }
                })
            }
        },

        beforeDestroy() {
	        $(window).off('resize', this.onResize)
        },
        mounted() {
	        this.$store.commit('hideAlert')
	        setTimeout(_ => {
                $('.login-form').fadeIn()
            }, 500)

	    	// 아이폰 A2HS 유도
            const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                // return /iphone|ipad|ipod/.test( userAgent );
                return /iphone|ipod/.test( userAgent );
            }
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

            if (isIos() && !isInStandaloneMode()) {
                this.device = 'ios'
                // $('.a2hs-wrap').slideDown()
                $('.landing').show();
            }



            let deferredPrompt;
            // 안드로이드 앱설치 유도
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                deferredPrompt = e;

                $('.landing').show()


                this.device = 'android'

                setTimeout(_ => {

                    $('.btn-install').click(_ => {
                        // Show the prompt
                        deferredPrompt.prompt();
                        // Wait for the user to respond to the prompt
                        deferredPrompt.userChoice.then((choiceResult) => {
                            if (choiceResult.outcome === 'accepted') {
                                console.log('User accepted the A2HS prompt');
                                deferredPrompt = null;
                                $('.a2hs-wrap').hide()
                            } else {
                                console.log('User dismissed the A2HS prompt');
                                location.reload()
                            }
                        });
                    })
                })
            });


            $(window).resize(this.onResize).resize()

        },

        methods: {
	        onResize() {
	            $('.landing').height(window.innerHeight)
            },
            login() {
                const self = this

                this.$store.commit('setUserAction', '1|')

			    if (!this.email || !/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
				    self.$store.commit('showDialog',{
					    type: 'alert',
					    message: '이메일 주소를 입력해주세요.',
					    okCb: ()=>{
						    setTimeout(this.$refs.email.focus)
					    }
				    });
				    return;
			    }
			    if (!this.passwd) {
				    self.$store.commit('showDialog',{
					    type: 'alert',
		                message: '비밀번호를 입력해주세요.',
		                okCb: ()=>{
			                setTimeout(this.$refs.passwd.focus)
		                }
	                });
	                return;
                }

			    const params = {
				    email: this.email,
				    passwd: this.passwd,
			    }
			    this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/login`, params).then(response => {
				    if (response.data.code === '9011') {

					    self.$store.commit('showDialog',{
						    type: 'alert',
						    message: response.data.message,
						    okCb: ()=>{
							    setTimeout(this.$refs.email.focus)
						    }
					    });
				    } else if (response.data.code === '9012') {

					    self.$store.commit('showDialog',{
						    type: 'alert',
						    message: response.data.message,
						    okCb: ()=>{
							    setTimeout(self.$refs.passwd.focus)
						    }
					    });
                    } else if (response.data.code === '9501' || response.data.code === '9502') {

                        self.$store.commit('showDialog',{
                            type: 'alert',
                            message: response.data.message.replace(/\\n/g, '\n'),
                            okCb: ()=>{
                            }
                        });
				    } else if (response.data.code === '0000') {
				    	const trainee = response.data.result
					    self.$cookie.set('token', trainee.token, 30)
                        self.$cookie.set('name', trainee.name, 30)
                        self.$cookie.set('id', trainee.id, 30)
                        self.$cookie.set('training_id', trainee.training_id, 30)

                        if (trainee.will_reset) {
                            localStorage.removeItem('startDateTime')
                            localStorage.removeItem(`userData${trainee.id}`)

                            this.$set(this, '$userData', { stage: 1, map: {}, hint: {}, hintItem: {}, characterHints: {}, noteStrengths: {}, strengths: [], strengthCompleted: {}, matchCounts: {}, points: 60, adminPoints: 0, usedHint: {}, valueSum: 0, missionOpened: {}, missionCompleted: {}, traineeStrengths: 0, })
                        }

                        if (process.env.VUE_APP_MODE !== 'dev') {
                            if (document.documentElement.requestFullscreen) {
                                document.documentElement.requestFullscreen()
                            }
                            if (document.documentElement.webkitEnterFullScreen) {
                                document.documentElement.webkitEnterFullScreen();
                            }
                        }
                        if (response.data.result.last_progress) {
                            this.$cookie.set('id', response.data.result.id, 30)
                            this.$cookie.set('training_id', response.data.result.training_id, 30)
                            location.replace(response.data.result.last_progress)
                        } else {
                            location.replace('/intro')
                        }
				    }
			    })
				    .catch(error => {
					    console.log(error)
				    })
		    }

	    }
    };
</script>

<style scoped lang="scss">
    .login-layout {
        width: 55vw;
        margin: 0 auto;
    }

    .logo-title {
        margin-left: -8%;
        margin-right: -8%;
    }

    .login-form {
        display: none;
        margin: 0 20%;
    }

    .input-field {
        height: 35px;
        background-color: #F4F0ED !important;
        border: unset !important;
        font-size: 16px;
    }

    .v-text-field--outlined >>> fieldset {
        border-width: 0 !important;
        background-color: #F4F0ED !important;
    }
    @media screen and (min-width: 1024px) {

        .login-form {
            width: 400px;
            padding-bottom: 100px;
        }
    }

    .a2hs-wrap {
        position: fixed;
	    bottom: 0;
        left: 0; right: 0;
        display: none;

        &.ios {
            bottom: calc(30*100vw/720);
        }

        .a2hs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;
            background-color: #f2f8ff;
	        border-radius: 10px;

            &>img {
                width: calc(90*100vw/720);
                border-radius: 7px;
            }

            .message {

	            word-break: keep-all;
                padding-left: 10px;
	            font-size: calc(30*100vw/720);

                img {
                    width: calc(25*100vw/720);
                }
            }

            &.ios:after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: calc(-30*100vw/720);
                border-width: calc(30*100vw/720);
                border-style: solid;
                border-color: #f2f8ff transparent transparent transparent;
            }
        }
    }

    .landing {
        display: none;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background-color: #DBD3CC;

        &>div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .ios {
            align-items: flex-end;
        }

        .android {
            align-items: center;

            display: flex;
            justify-content: center;
            align-items: flex-start;

            &>div {
                position: absolute;
                width: calc(425*(100vmin/720));
                top: 50%;

                img {
                    margin-top: calc(188*100vmin/720);
                }
            }
        }
    }
</style>

<style>
    .theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
        background-color: #F4F0ED !important;
    }
</style>
