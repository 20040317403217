import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/index.html',
            redirect: '/login',
        },
        {
            path: '/',
            redirect: 'login',
        },
        {
            path: '/landing',
            name: 'Landing',
            component: require('../components/login/Landing').default
        },
        {
            path: '/login',
            name: 'Login',
            component: require('../components/login/Login').default
        },
        {
            path: '/intro',
            name: 'Intro',
            component: require('../components/intro/Intro').default
        },
        {
            path: '/play/video',
            name: 'Video',
            component: require('../components/intro/Video').default
        },
        {
            path: '/strengths/first',
            name: 'FirstStrengths',
            component: require('../components/strengths/StrengthSelect').default
        },
        {
            path: '/strengths/final',
            name: 'FinalStrengths',
            component: require('../components/strengths/StrengthSelect').default
        },
        {
            path: '/names',
            name: 'Names',
            component: require('../components/play/Names').default
        },
        {
            path: '/play',
            name: 'Play',
            component: require('../components/play/Play').default
        },
        {
            path: '/outro',
            name: 'Outro',
            component: require('../components/outro/Outro').default
        },

    ]
})
