<template>
	<v-sheet class="popup-layout" width="100%" height="100%">
		<v-sheet class="popup-wrap" width="100%" height="100%">
			<div class="popup-title"
			     :style="{fontSize: `${40*$gameData.scale}px`, height: `${60*$gameData.scale}px`}"
			>
				예상 강점
				<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="v => {$emit('close'); playAudio(1);}" />
			</div>
			<div class="body">
				<v-layout class="buttongroup pt-2" column justify-center align-center>
					<div class="buttons">
						<div class="button"
						     v-for="(strength, index) in strengths.slice(0,4)"
						     :key="index"
						     @click="onSelect(strength)"
						>
							<img v-if="selectedImageNo==strength.image_num" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_on.png`" width="100%"  />
							<img v-else :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_off.png`" width="100%"  />
						</div>
					</div>
					<div class="buttons">
						<div class="button"
						     v-for="(strength, index) in strengths.slice(4,7)"
						     :key="index"
						     @click="onSelect(strength)"
						>
							<img v-if="selectedImageNo==strength.image_num" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_on.png`" width="100%"  />
							<img v-else :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_off.png`" width="100%"  />
						</div>
					</div>
					<div class="buttons">
						<div class="button"
						     v-for="(strength, index) in strengths.slice(7,12)"
						     :key="index"
						     @click="onSelect(strength)"
						>
							<img v-if="selectedImageNo==strength.image_num" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_on.png`" width="100%"  />
							<img v-else :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_off.png`" width="100%"  />
						</div>
					</div>
					<div class="buttons">
						<div class="button"
						     v-for="(strength, index) in strengths.slice(12,16)"
						     :key="index"
						     @click="onSelect(strength)"
						>
							<img v-if="selectedImageNo==strength.image_num" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_on.png`" width="100%"  />
							<img v-else :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_off.png`" width="100%"  />
						</div>
					</div>
					<v-img v-if="!!selectedImageNo" class="btn-select mt-2" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength_select_on.png`" width="100" @click="onCompleted()" contain />
					<v-img v-else class="btn-select mt-2" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength_select_off.png`" contain />
				</v-layout>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<script>
	export default {
		data() {
			return {
				strengths: this.$gameData.strengths,
				selectedImageNo : '',
			}
		},
		props: [
			'imageNo',
		],
		mounted() {
			this.getList()
			this.selectedImageNo = this.imageNo
		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			getList() {
				const self = this
			},
			onSelect(strength) {
				this.playAudio(3)
				this.selectedImageNo = strength.image_num
			},
			onCompleted() {
				this.playAudio(1)
				this.$eventBus.$emit('ON_SELECT_STRENGTH', this.selectedImageNo)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.popup-layout {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3%;
		background-color: #3d3d3d;
		z-index: 500;

		.popup-wrap {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			border: solid 5px #febd36;
			background-color: #ffe5c0;
			display: flex;
			flex-direction: column;

			.popup-title {
				height: 45px;
				background-color: #febd36;
				font-weight: 400;
				font-family: KBFGDisplayB;
				font-size: 25px;
				color: #f9f9f9;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					height: 80%;
					position: absolute;
					right: 1%;
					top: 1%;
				}
			}

			.body {
				padding: 1%;
				height: 100%;
				overflow-y: scroll;
				position: relative;

				.buttons {
					.button {
						margin: 1px 4px;
						width: 110px;
						max-width: 16vw;
					}
				}

				.btn-select {
					display: inline-block;
					width: 100px;
					max-width: 15vw;
				}
			}

			.button {
				display: inline-block;
			}

		}
	}
</style>
<style>
	img {
		max-width: 100%;
	}
</style>
