<template>
	<div class="audio-widget">
		<!--
		<audio class="bgm" ref="bgm"
		       :src="bgm"
		       loop
		       muted
		/>
		<audio class="sound" ref="sound"
		       :src="sound"
		/>
		-->
	</div>
</template>

<script>
	export default {
		name: 'AudioWidget',

		data() {
			return {
				audios: {},
				apiUrl: process.env.VUE_APP_SERVER_HOST,

				bgmAudios: {},
				soundAudios: {},
				bgmAudio: null,
				soundAudio: null,
				gameBgmAudio: null,
				willPlayBgm: false,

				bgm: '',
				sound: '',

				isInit: false,
			}
		},
		computed: {

			isMuted() {
				return this.$store.getters.isMuted
			},

			userAction() {
				return this.$store.getters.userAction
			}
		},
		watch: {
			userAction(val) {

				if (val) {

					val = val.split('|')[0]

					if (val) {
						val = parseInt(val)

						if (val === -1) {
							if (!this.isInit) {
								this.willPlayBgm = true
							} else {
								this.gameBgmAudio.play()
							}

						}

						if ([6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].indexOf(val) > -1) {
							this.setBgm(val)
							// this.bgmAudio.src = audio
							// this.bgmAudio.load()
							// this.bgmAudio.play()
						} else {
							this.setSound(val)
							// this.bgmAudio.volume = 0.2
							// this.soundAudio.src = audio
							// this.soundAudio.load()
							// this.soundAudio.play()
						}
					} else {
						this.setBgm(val)
					}
				}
			},

			isMuted(val) {
				if (this.bgmAudio) {
					this.bgmAudio.muted = val
				}

				if (this.soundAudio) {
					this.soundAudio.muted = val
				}

				this.gameBgmAudio.muted = val
			}
		},

		beforeCreate() {
		},

		mounted() {
			this.loadAudio()

			this.gameBgmAudio = new Audio()
			this.gameBgmAudio.loop = true
			this.gameBgmAudio.src = 'https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/kbcsi_bgm.mp3'

			this.$eventBus.$on('INIT_AUDIO', _ => {
				if (this.isInit) return;

				Object.keys(this.bgmAudios).forEach(key => {

					const audio = this.bgmAudios[key]

					audio.play()
					audio.pause()
					audio.currentTime = 0
				})

				Object.keys(this.soundAudios).forEach(key => {

					const audio = this.soundAudios[key]

					audio.play()
					audio.pause()
					audio.currentTime = 0
				})

				if (this.willPlayBgm) {
					this.gameBgmAudio.play()
				} else {
					this.gameBgmAudio.play()
					this.gameBgmAudio.pause()
					this.gameBgmAudio.currentTime = 0
				}

				this.isInit = true

			})

			this.$eventBus.$on('STOP_ALL', _ => {
				if (this.isInit) {
					Object.keys(this.bgmAudios).forEach(key => {
						const audio = this.bgmAudios[key]
						audio.pause()
						audio.currentTime = 0
					})

					Object.keys(this.soundAudios).forEach(key => {
						const audio = this.soundAudios[key]
						audio.pause()
						audio.currentTime = 0
					})
					this.gameBgmAudio.pause()
				}
			})
		},

		methods: {

			onLoadedAudio(e) {

				let promise = e.target.play()

				if (promise !== undefined) {
					promise.then(_ => {
					}).catch(error => {
					});
				}
			},

			switchSound() {
				this.$store.commit('setMute', !this.isMuted)
				// $('audio').each((idx, node) => {
				//     node.muted = this.isMuted
				// })

				if (this.bgmAudio) {
					this.bgmAudio.muted = this.isMuted
				}
				if (this.soundAudio) {
					this.soundAudio.muted = this.isMuted
				}
			},

			loadAudio() {
				this.axios.get(`${this.apiUrl}/api/audio`).then(response => {

					if (response.data.code === '0000') {
						const audios = response.data.result

						audios.forEach(item => {
							const audio = new Audio()
							audio.src = item.url

							if ([6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].indexOf(item.id) > -1) {

								// audio.loop = true
								this.bgmAudios[item.id] = audio
							} else {
								audio.addEventListener('ended', _ => {
									if (this.bgmAudio) {
										// this.bgmAudio.volume = 1
									}
								})
								this.soundAudios[item.id] = audio
							}

						})
					}

				})
			},
			setBgm(key) {

				if (!key && this.bgmAudio) {
					this.bgmAudio.pause()
					this.bgmAudio.currentTime = 0
					this.bgmAudio = null

					return

				}

				if (this.bgmAudio === this.bgmAudios[key]) {
					this.bgmAudio.play().catch(e => {
						console.log(e)
					})
					return
				}

				if (this.bgmAudio) {
					this.bgmAudio.pause()
					this.bgmAudio.currentTime = 0
					this.bgmAudio = null
				}

				if (!this.bgmAudios[key]) return

				this.bgmAudio = this.bgmAudios[key]
				this.bgmAudio.muted = this.isMuted
				this.bgmAudio.play().catch(e => {
					console.log(e)
				})


			},
			setSound(key) {

				if (this.soundAudio) {
					this.soundAudio.pause()
					this.soundAudio.currentTime = 0
					this.soundAudio = null
				}

				if (!this.soundAudios[key]) return

				if (this.bgmAudio) {
					// this.bgmAudio.volume = 0.2
				}

				this.soundAudio = this.soundAudios[key]
				this.soundAudio.muted = this.isMuted
				this.soundAudio.currentTime = 0
				this.soundAudio.play().catch(e => {
					console.log(e)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.audio-widget {
		position: absolute;
		z-index: 0;
		width: 1px;
		height: 1px;
		right: 0;
		bottom: 0;
		visibility: hidden;
	}
</style>
