<template>
	<v-layout class="video-wrap" column>
		<video ref="video"
			src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/tutorial.mp4"
			class="video"
			autoplay playsinline
		       muted="false"
			@loadeddata="onVideoLoaded"
		       @ended="onVideoEnded"
		       @click="onClick"
			></video>
		<div class="wrap">
			<img class="btn-control btn-pause" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_pause.png" @click="pause()" />
			<img class="btn-control btn-play" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_play.png" @click="play()" />
			<img class="btn-skip" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_skip.png" @click="skip()" />
		</div>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			st: null,
		}
	},
	mounted() {
		// if (!this.$cookie.get('watched_video')) {
		// 	$('.btn-skip').css('visibility', 'hidden')
		// }
	},
	beforeRouteLeave(to, from, next) {
		const video = $('video').get(0)

		video.play()
		video.pause()

		next()
	},
	beforeRouteEnter(to, from, next) {

		next(vm => {
			const params = {
				progress: vm.$route.path,
			}

			vm.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/progress`, params)
		})
	},
	methods: {
		onVideoLoaded(e) {
			let promise = e.target.play()

			if (promise !== undefined) {
				promise.then(_ => {
					e.target.muted = false
				}).catch(error => {
				});
			}
		},
		onVideoEnded(e) {
			this.$cookie.set('watched_video', true)
			this.$router.push('/play')
		},
		onClick(e) {
			if (this.st) {
				clearTimeout(this.st)
				this.st = null
			}
			if (!e.target.paused) {
				$('.btn-pause').fadeIn()
				$('.btn-skip').fadeIn()

				this.st = setTimeout(_ => {
					$('.btn-control').fadeOut()
					$('.btn-skip').fadeOut()
					this.st = null
				}, 2000)
			} else {
				$('.btn-play').fadeIn()
				$('.btn-skip').fadeIn()
			}
		},

		play() {
			$('.btn-play').fadeOut()
			$('.btn-skip').fadeOut()

			$('.video').get(0).play()
		},

		pause() {
			if (this.st) {
				clearTimeout(this.st)
				this.st = null
			}

			$('.btn-pause').fadeOut()
			$('.btn-play').fadeIn()

			$('.video').get(0).pause()
		},

		skip() {
			this.$router.push('/play')
		}
	},
}
</script>

<style lang="scss" scoped>
	.video-wrap {
		.wrap {
			position: fixed;
			left: 0; right: 0;
			top: 0; bottom: 0;
			pointer-events: none;
			font-weight: 400;
			font-family: KBFGDisplayB;
			color: #febd36;
			border: 5px solid #febd36;
			display: flex;
			justify-content: center;
			align-items: center;

			.btn-control {
				position: absolute;
				width: calc(52*100vw/640);
				display: none;
				pointer-events: all;
			}

			.btn-skip {
				display: none;
				width: calc(138*100vw/640);
				position: absolute;
				right: calc(20*100vw/640);
				bottom: calc(20*100vw/640);
				pointer-events: all;
			}
		}

		.video {
			background-color: #000;
			height: 100vh;
		}

	}
</style>
