<template>
    <v-main class="main fill-height">
        <!-- 상단 바 -->
        <v-layout class="nav-top align-end">
            <img v-show="mode === 'map'" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/label_${$gameData.mapData.id}.png`" class="label"/>
            <v-spacer></v-spacer>
            <!--            <div style="width: 35px; height: 37px">-->
            <!--		        <v-text-field label="Stage" type="number" v-model="$userData.stage" dense hide-details />-->
            <!--            </div>-->
            <div class="btn-help d-inline-block ma-1"><img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_help.png" class="" @click="v => { openHelp = true; playAudio(1)}" /></div>
            <div class="btn-message d-inline-block ma-1"><img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_message.png" @click="v => {openMessage = true; playAudio(1)}" /><img class="icon-new" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_new.png" v-if="hasNewMessage" /></div>
            <div class="btn-keywords d-inline-block ma-1"><img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_keywords.png" @click="v => {openStrength = true; playAudio(1)}"/></div>
            <div class="btn-hint d-inline-block ma-1"><img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_hint.png" @click="v => {openAndShowNote(); playAudio(1) }" /></div>
            <div class="time d-inline-block ma-1" :class="timeWaring? 'warn' : ''">
                <p>{{timeRemaining | format}}</p>
                <!--                <div v-if="timeRemaining > 10" style="position:fixed; margin-top: -10px;">-->
                <!--                    <v-btn x-small @click="timeRemaining = 5">남은시간 5초로</v-btn>-->
                <!--                </div>-->
            </div>
            <div class="point d-inline-block ma-1">
                <p>{{ this.$userData.points }}</p>
                <div style="position:fixed; margin-top: 0px; right: 10px;">
                    <v-btn
                            style="background-color: #a29d99"
                            icon
                            color="white"
                            @click="$store.commit('setMute',!$store.getters.isMuted)">
                        <v-icon v-if="!$store.getters.isMuted">mdi-volume-high</v-icon>
                        <v-icon v-else>mdi-volume-off</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="btn-logout d-inline-block ma-1"><img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_logout.png" @click="v => {logout(); playAudio(1)}"/></div>
        </v-layout>

        <!-- 왼쪽 버튼 -->
        <v-layout v-show="mode === 'map' && ($gameData.mapData.id) < 4" class="nav-left" column >
            <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_1f_${$gameData.mapData.id===1?'on':'off'}.png`" @click="v => {changeMapId(1); playAudio(1) }" />
            <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_3f_${$gameData.mapData.id===2?'on':'off'}.png`" @click="v => {changeMapId(2); playAudio(1) }" />
            <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_5f_${$gameData.mapData.id===3?'on':'off'}.png`" @click="v => {changeMapId(3); playAudio(1) }" />
        </v-layout>


        <!-- 메인 뷰 -->
        <transition-group name="fade" type="in-out" duration="500">
            <!-- 회사 Map -->
            <map-view key="map" v-if="mode === 'map'" ref="map" @change="onMapChanged" />

            <!-- 힌트 -->
            <hint key="hint" v-if="mode === 'hint'" ref="hint" />

            <!-- 팀원보기 -->
            <team key="team" v-if="mode === 'team'" ref="team" />

            <!-- 미션보기 -->
            <mission key="mission" v-if="mode === 'mission'" ref="mission" />
        </transition-group>


        <!-- 하단 바 -->
        <v-layout class="nav-bottom pa-1">

            <!-- 회사 Map 버튼 -->
            <div class="btn-map d-inline-block ma-1"><img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_map${mode==='map' && $gameData.mapData && $gameData.mapData.id < 4?'_selected':''}.png`" width="100%" class="" @click="v => {changeMapId(1); playAudio(1) }" /></div>

            <!-- 팀원 보기 버튼-->
            <div class="btn-team d-inline-block ma-1"><img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_team${mode==='team'?'_selected':''}.png`" width="100%" class="" @click="v => {moveToTeam(); playAudio(1) }" /><img class="icon-new" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_new.png" v-if="hasNewTeam" /></div>

            <!-- 미션 보기 버튼 -->
            <div class="btn-mission d-inline-block ma-1"><img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_mission${mode==='mission'?'_selected':''}.png`" width="100%" class="" @click="v => {moveToMission(); playAudio(1) }" /><img class="icon-new" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_new.png" v-if="hasNewMission" /></div>

            <v-spacer></v-spacer>

            <!-- 뒤로가기 버튼 -->
            <div v-if="canBack" class="btn-map d-inline-block ma-1" @click="v => {goBack(); playAudio(1) }"><img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_back.png" width="100%" class="" /></div>

            <!-- 매칭 버튼 -->
            <div v-if="showMatchButton" class="btn-match d-inline-block ma-1" @click="v => {tryMatch();}">
                <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match${!canMatch ? '_disabled' : ''}.png`" width="100%" class="" />
            </div>

            <!-- 제출 버튼 -->
            <div v-if="showSubmitButton" class="btn-submit d-inline-block ma-1" @click="v => {submit();}">
                <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_submit${!canSubmit? '_disabled' : ''}.png`" width="100%" class="" />
            </div>

            <!-- 배치 버튼 -->
            <div v-if="showArrangeButton" class="btn-arrange d-inline-block ma-1" @click="v => {arrange(); }">
                <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_team_arrange${!canArrange? '_disabled' : ''}.png`" width="100%" class="" />
            </div>
        </v-layout>


        <!-- 팝업 -->
        <transition-group name="fade" type="in-out" duration="500">
            <team-detail key="team-detail" :no="teamDetailNo" v-if="openTeamDetail" ref="team-detail" />
            <quiz key="quiz" :quiz="quiz" v-if="openQuiz" ref="quiz" />
            <project key="project" :project="project" v-if="openProject" ref="project" />

            <help-popup key="help" v-if="openHelp" @close="openHelp = false" />
            <message-popup key="message" v-if="openMessage" @close="openMessage = false" />
            <strength-popup key="strength" v-if="openStrength" @close="openStrength = false" />
            <div key="note" v-if="openNote || openNoteDetail" v-show="showNote || showNoteDetail" class="popup-layout">
                <note-popup v-if="openNote" v-show="showNote" @close="openNote = false" />
                <note-detail-popup :no="noteDetailNo" v-if="openNoteDetail" v-show="showNoteDetail" @back="v => {showNote = true; openNoteDetail = false}" @close="openNoteDetail = openNote = false" />
            </div>
            <strength-select-popup key="strengthSelect" v-if="openStrengthSelect" :imageNo="strengthImageNo" @close="openStrengthSelect = false" />
            <qr-code-reader key="qr-reader" :imageNo="qrImageNo" :type="qrType" v-if="openQrReader" />

        </transition-group>

        <transition>
            <v-layout class="result" v-if="result" column>
                <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/result_${result}.png`"
                     :style="{
                        width: `${927*$gameData.scale}px`,
                        height: `${575*$gameData.scale}px`,
                     }"
                />
                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_final_strengths.png"
                     :style="{
                        width: `${210*$gameData.scale}px`,
                        height: `${75*$gameData.scale}px`,
                        marginTop: `${18*$gameData.scale}px`,
                     }"
                     @click="moveToOutro"
                />
            </v-layout>
        </transition>


    </v-main>
</template>

<script>
    import io from 'socket.io-client';

    import MapView from "./MapView"
    import Hint from "./Hint"
    import Team from "./Team";
    import Mission from "./Mission";

    import TeamDetail from "./TeamDetail";
    import Quiz from "./Quiz";
    import Project from "./Project";

    import HelpPopup from "../popups/HelpPopup";
    import MessagePopup from "../popups/MessagePopup";
    import StrengthPopup from "../popups/StrengthPopup";
    import NotePopup from "../popups/NotePopup";
    import NoteDetailPopup from "../popups/NoteDetailPopup";
    import StrengthSelectPopup from "../popups/StrengthSelectPopup";

    import QrCodeReader from "../popups/QrCodeReader";


    export default {
        components: {
            MapView,
            Hint,
            Team,
            Mission,

            TeamDetail,
            Quiz,
            Project,

            HelpPopup,
            MessagePopup,
            StrengthPopup,
            NotePopup,
            NoteDetailPopup,
            StrengthSelectPopup,

            QrCodeReader,
        },

        data() {
            return {
                socket: null,

                timeRemaining: 0,
                timeCountDown: null,

                mode: '',
                timeWaring: false,
                openHelp: false,
                openMessage: false,
                openStrength: false,
                openNote: false,
                showNote: true,
                openNoteDetail: false,
                showNoteDetail: true,
                openStrengthSelect: false,
                openQrReader: false,

                openTeamDetail: false,
                openQuiz: false,
                openProject: false,

                showMatchButton: false,
                canMatch: false,
                showSubmitButton: false,
                canSubmit: false,
                showArrangeButton: false,
                canArrange: false,
                strengthImageNo: '',
                qrImageNo: '',
                qrType: '',
                noteDetailNo: 0,
                teamDetailNo: 0,
                hasNewTeam: false,
                hasNewMission: false,
                hasNewMessage: false,

                quiz: null,
                project: null,

                result: '',

                messages: [],
            }
        },
        filters: {
            format(time) {
                const lpad = str => {
                    str = str.toString()
                    while (str.length < 2) {
                        str = '0' + str;
                    }

                    return str
                }
                let sec = time % 60
                let min = Math.floor(time / 60)
                let hour = ''

                if (min >= 60) {
                    hour = Math.floor(min / 60)
                    min = min % 60
                }

                return (hour ? lpad(hour) + ':' : '') + lpad(min) + ':' + lpad(sec)
            }
        },
        computed: {
            screenWidth() {
                return screen.width
            },
            screenHeight() {
                return screen.height
            },
            canBack() {

                if (this.mode === 'team') {
                    return this.openTeamDetail
                }

                if (this.mode === 'mission') {
                    if (this.openQuiz) return true
                    if (this.openProject) return true

                    return false
                }

                if (!!this.$gameData.mapData.parent_id || this.mode === 'hint') return true

                return false
            }
        },

        watch: {
            mode(val) {
                if (val && val !== 'map' && val !== 'hint') {
                    this.playAudio('')
                }
            },
            '$userData.points': {
                handler(val) {
                    // this.saveUserData()
                }
            }
        },

        beforeDestroy() {
            this.$eventBus.$emit('STOP_ALL')

            this.$eventBus.$off('OPEN_HINT')
            this.$eventBus.$off('CLOSE_NOTE')
            this.$eventBus.$off('HIDE_NOTE')
            this.$eventBus.$off('NOTE_DETAIL')
            this.$eventBus.$off('HIDE_NOTE_DETAIL')
            this.$eventBus.$off('CLOSE_NOTE_DETAIL')
            this.$eventBus.$off('OPEN_STRENGTH_SELECT')
            this.$eventBus.$off('CLOSE_STRENGTH_SELECT')
            this.$eventBus.$off('TEAM_DETAIL')
            this.$eventBus.$off('CLOSE_TEAM_DETAIL')
            this.$eventBus.$off('OPEN_QR_READER')
            this.$eventBus.$off('CLOSE_QR_READER')
            this.$eventBus.$off('CAN_MATCH')
            this.$eventBus.$off('OPEN_QUIZ')
            this.$eventBus.$off('CLOSE_QUIZ')
            this.$eventBus.$off('CAN_SUBMIT')
            this.$eventBus.$off('OPEN_PROJECT')
            this.$eventBus.$off('CLOSE_PROJECT')
            this.$eventBus.$off('CAN_ARRANGE')
            this.$eventBus.$off('NEW_MESSAGE')
            this.$eventBus.$off('NEW_TEAM')
            this.$eventBus.$off('NEW_MISSION')
            this.$eventBus.$off('CHECK_MISSION')
            this.$eventBus.$off('SEND_MESSAGE')

            this.socket.close()

        },

        async beforeMount() {
            const self = this

            // this.socket = io(process.env.VUE_APP_SOCKET_HOST)
            this.socket = io(process.env.VUE_APP_SOCKET_HOST, {
                transports: ['websocket'],
                secure: true,
            })
            this.setSocketListener()

            let data

            // 이름 목록
            let response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/names`)
            if (response.data.code === '0000') {

                data = response.data.result

                if (!data || data.length === 0) {
                    this.$router.replace('/strengths/first')
                    return;
                }

                const lastNames = ['김','이','박','최','정','강','조','윤']
                Object.keys(data).forEach((key, index) => {
                    data[key] = lastNames[index] + data[key]
                })

                self.$gameData.names = data
            } else {
                // self.$store.commit('showSnackbar',{
                //     message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
                //     color: 'error',
                //     duration: 3000
                // });
            }

            // 강점 목록
            response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/strengths`)
            if (response.data.code === '0000') {
                data = response.data.result
                self.$gameData.strengths = data
            } else {
                // self.$store.commit('showSnackbar',{
                //     message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
                //     color: 'error',
                //     duration: 3000
                // });
            }


            // 캐릭터 목록
            response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/characters`)
            if (response.data.code === '0000') {
                self.$gameData.characters = response.data.result
            } else {
                // self.$store.commit('showSnackbar',{
                //     message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
                //     color: 'error',
                //     duration: 3000
                // });
            }

            // 지도데이터
            response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/maps`)
            if (response.data.code === '0000') {
                self.maps = {}
                response.data.result.forEach(map => {
                    if (map.links) {
                        map.links = JSON.parse(map.links)
                    }
                    if (map.usernames) {
                        map.usernames = JSON.parse(map.usernames)
                    }

                    self.$gameData.maps[map.id] = map

                })

                if (self.$gameData.hints.length > 0) {
                    self.changeMapId(1)
                }
            } else {
                // self.$store.commit('showSnackbar',{
                //     message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
                //     color: 'error',
                //     duration: 3000
                // });
            }

            // 힌트데이터
            response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/hints`)
            if (response.data.code === '0000') {
                response.data.result.forEach(hint => {
                    self.$gameData.hints[hint.id] = hint
                })

                if (Object.keys(self.$gameData.maps).length > 0) {
                    self.changeMapId(1)
                }
                // this.mode = 'map'
            } else {
                // self.$store.commit('showSnackbar',{
                //     message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
                //     color: 'error',
                //     duration: 3000
                // });
            }


            // 미션 데이터
            response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/missions`)
            response.data.result.forEach(mission => {

                mission.name = this.replaceUserName(mission.name)


                if (!this.$gameData.missions[mission.level]) {
                    this.$set(this.$gameData.missions, mission.level, {})
                }
                this.$set(this.$gameData.missions[mission.level], mission.id, mission)


                if (!this.$userData.missionOpened[mission.level]) {
                    this.$set(this.$userData.missionOpened, mission.level, {})
                }
                if (!this.$userData.missionOpened[mission.level][mission.id]) {
                    this.$set(this.$userData.missionOpened[mission.level], mission.id, mission.level === 1)
                }

                if (!this.$userData.missionCompleted[mission.level]) {
                    this.$set(this.$userData.missionCompleted, mission.level, {})
                }
                if (!this.$userData.missionCompleted[mission.level][mission.id]) {
                    this.$set(this.$userData.missionCompleted[mission.level], mission.id, null)
                }

            })

        },
        beforeRouteEnter(to, from, next) {

            next(vm => {
                const params = {
                    progress: vm.$route.path,
                }

                vm.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/progress`, params)
            })
        },


        mounted() {
            const self = this

            this.playAudio(-1)

            this.messages = JSON.parse(localStorage.getItem('messages') || '[]')

            this.$eventBus.$on('OPEN_HINT', hint => {
                self.mode = ''
                setTimeout(_ => {
                    self.mode = 'hint'
                })
                self.$gameData.hint = self.$gameData.hints[hint]
            })

            this.$eventBus.$on('CLOSE_NOTE', _ => {
                self.openNote = false
            })

            this.$eventBus.$on('HIDE_NOTE', _ => {
                self.showNote = false
            })

            this.$eventBus.$on('NOTE_DETAIL', no => {
                self.noteDetailNo = no
                self.showNote = false
                self.openNoteDetail = true
            })

            this.$eventBus.$on('HIDE_NOTE_DETAIL', _ => {
                self.showNoteDetail = false
            })

            this.$eventBus.$on('CLOSE_NOTE_DETAIL', _ => {
                self.openNoteDetail = false
            })

            this.$eventBus.$on('OPEN_STRENGTH_SELECT', imgNo => {
                this.strengthImageNo = imgNo
                self.openStrengthSelect = true
            })
            this.$eventBus.$on('CLOSE_STRENGTH_SELECT', _ => {
                self.openStrengthSelect = false
            })

            this.$eventBus.$on('TEAM_DETAIL', no => {
                self.openTeamDetail = true
                self.teamDetailNo = no
                self.showMatchButton = true
                self.canMatch = false
            })
            this.$eventBus.$on('CLOSE_TEAM_DETAIL', _ => {
                self.openTeamDetail = false
                self.showMatchButton = false
                self.canMatch = false
            })

            this.$eventBus.$on('OPEN_QR_READER', (imgNo, type) => {
                this.qrImageNo= imgNo
                this.qrType = type || 'strength'
                self.openQrReader = true
            })

            this.$eventBus.$on('CLOSE_QR_READER', _ => {
                this.playAudio(20)
                self.openQrReader = false
            })

            this.$eventBus.$on('CAN_MATCH', canMatch => {
                self.canMatch = canMatch
            })

            this.$eventBus.$on('OPEN_QUIZ', quiz => {
                self.openQuiz = true
                self.quiz = quiz
                self.showSubmitButton = true
                self.canSubmit = false
            })

            this.$eventBus.$on('CLOSE_QUIZ', _ => {
                self.openQuiz = false
                self.showSubmitButton = false
                self.canSubmit = false
            })

            this.$eventBus.$on('CAN_SUBMIT', canSubmit => {
                this.canSubmit = canSubmit
            })

            this.$eventBus.$on('OPEN_PROJECT', project => {
                self.openProject = true
                self.project = project
                self.showArrangeButton = true
                self.canArrange = false
            })

            this.$eventBus.$on('CLOSE_PROJECT', _ => {
                self.openProject = false
                self.showArrangeButton = false
                self.canArrange = false
            })

            this.$eventBus.$on('CAN_ARRANGE', canArrange => {
                this.canArrange = canArrange
            })



            this.$eventBus.$on('NEW_MESSAGE', isNew => {
                this.hasNewMessage = isNew
            })

            this.$eventBus.$on('NEW_TEAM', isNew => {
                this.hasNewTeam = isNew
            })

            this.$eventBus.$on('NEW_MISSION', isNew => {
                this.hasNewMission = isNew
            })

            this.$eventBus.$on('CHECK_MISSION', val => {

                let completedCount = 0

                if (val && !(this.$userData.valueSum & val)) {
                    this.$userData.valueSum += val
                }
                const valueSum = this.$userData.valueSum

                Object.keys(this.$gameData.missions).forEach(level => {
                    const levelMissions = this.$gameData.missions[level]

                    Object.keys(levelMissions).forEach(key => {
                        const mission = levelMissions[key]

                        if (valueSum && mission.complete_value & valueSum || (valueSum && mission.complete_value2 && mission.complete_value2 & valueSum)) {
                            // this.$userData.missionSum += Math.pow(2, mission.id)
                            // 선행 조건이 2개인 경우
                            // 처음 완료한 선행 조건은 false를 셋팅
                            if (mission.complete_value2) {
                                if (this.$userData.missionCompleted[mission.level][mission.id] === null) {
                                    this.$set(this.$userData.missionCompleted[mission.level], mission.id, false)
                                } else if (this.$userData.missionCompleted[mission.level][mission.id] === false) {
                                    this.$set(this.$userData.missionCompleted[mission.level], mission.id, true)
                                    this.$userData.points += mission.point
                                    if (this.mode !== 'mission') {
                                        this.hasNewMission = true
                                    }
                                }
                            } else if (!this.$userData.missionCompleted[mission.level][mission.id]) {
                                this.$set(this.$userData.missionCompleted[mission.level], mission.id, true)
                                this.$userData.points += mission.point
                                if (this.mode !== 'mission') {
                                    this.hasNewMission = true
                                }
                            }

                        }


                        if (level > 1) {
                            if (this.$userData.missionCompleted[level - 1][mission.open_condition1] && this.$userData.missionCompleted[level - 1][mission.open_condition2]) {

                                if (this.$userData.missionOpened[level - 1][mission.open_condition1] && this.$userData.missionOpened[level - 1][mission.open_condition2]) {

                                    if (!this.$userData.missionOpened[level][mission.id]) {
                                        this.$set(this.$userData.missionOpened[level], mission.id, true)
                                    }
                                }
                            }
                        }

                        if (this.$userData.missionCompleted[level][mission.id]) {
                            completedCount++
                        }
                    })

                })


                // 모든 미션을 완료했고 모든 팀원 강점을 매칭하면 게임 종료
                if (completedCount === 21) {
                    if (Object.keys(this.$userData.strengthCompleted).map(key => {
                        return this.$userData.strengthCompleted[key]
                    }).length === 8) {
                        this.setGameOver()
                    }
                }

                if (this.$userData.stage < 3 && completedCount === 15) {
                    this.playAudio(21)
                    this.$userData.stage = 3
                    let message = '세 째 날이 되었습니다.\n' +
                            '[username6], [username7] 에 대한 단서가 추가 오픈되었습니다.\n' +
                            '(FAKE 단서에 주의하세요!)'
                    setTimeout(_ => {
                        this.$store.commit('showAlert', {
                            message: this.replaceUserName(message)
                        })
                    })

                    this.hasNewTeam = true
                } else if (this.$userData.stage < 2 && completedCount === 11) {
                    this.playAudio(21)
                    this.$userData.stage = 2
                    let message = '둘째 날이 되었습니다.\n[username3], [username4], [username5] 에 대한 단서가 추가 오픈되었습니다.\n누군가에게는 그 사람의 강점일 수 있지만,\n누군가는 그렇게 생각하지 않을 수도 있습니다.\n(FAKE 단서에 주의하세요!)'
                    setTimeout(_ => {
                        this.$store.commit('showAlert', {
                            message: this.replaceUserName(message)
                        })
                    })
                    this.hasNewTeam = true
                }

                this.saveUserData()
            })

        },
        methods: {

            playAudio(id) {
                setTimeout(_ => {
                    this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
                })
            },
            setSocketListener() {
                let self = this
                this.socket.on('connect', data => {
                    console.log('connected')

                    const trainee = {
                        id: this.$cookie.get('id'),
                        training_id: this.$cookie.get('training_id'),
                        name: this.$cookie.get('name'),
                    }

                    this.socket.emit('setTrainee', trainee)
                })
                this.socket.on('disconnect', _ => {
                    console.log('disconnected')
                })
                this.socket.on('back-to-game', _ => {
                    location.replace('/play')
                })

                this.socket.on('reset', _ => {
                    this.$eventBus.$emit('STOP_ALL')

                    localStorage.removeItem('startDateTime')
                    localStorage.removeItem(`message`)

                    this.$cookie.delete('token')
                    this.$cookie.delete('training_id')
                    this.$cookie.delete('id')
                    this.$cookie.delete('name')

                    this.$router.replace('/')
                    this.$store.commit('hideAlert')
                })

                this.socket.on('time', async time => {

                    let startDateTime = localStorage.getItem('startDateTime')
                    if (!startDateTime) {
                        startDateTime = await this.sendStartDateTime()
                    }

                    const startDt = this.$moment(startDateTime)
                    const gamePlayTime = Math.floor(this.$moment.duration(this.$moment() - startDt).asSeconds())

                    time = time - gamePlayTime

                    // 남은 시간 계산
                    this.timeRemaining = time

                    if (time < 1) {
                        this.setGameOver()
                        return
                    }
                    this.startTimeCountDown()
                })

                this.socket.on('outro', _ => {
                    this.moveToOutro()
                })

                const onMessage = message => {

                    this.playAudio(5)
                    if (!this.openMessage) {
                        this.hasNewMessage = true
                    }

                    this.messages.push({
                        message: message
                    })
                    localStorage.setItem('messages', JSON.stringify(this.messages))
                    this.$eventBus.$emit('RECEIVED_MESSAGE')
                }

                this.socket.on('message', onMessage)
                this.socket.on('admin-message', onMessage)

                this.socket.on('start-strength', _ => {
                    this.setGameOver()
                    // this.moveToOutro()
                })

                this.socket.on('finish-game', _ => {
                    this.$store.commit('showAlert', {
                        message: '게임이 모두 끝났습니다.\n감사합니다!',
                        buttons: []
                    })
                    // this.moveToOutro()
                })

                this.socket.on('send-point', point => {
                    let message = ''
                    if (point > 0) {
                        message = `관리자가 ${point} 포인트를 지급했습니다.`
                    } else {
                        message = `관리자가 ${-point} 포인트를 차감했습니다.`
                    }
                    self.$store.commit('showSnackbar',{
                        message: message,
                        color: 'success',
                        duration: 3000
                    });

                    this.$userData.points += parseInt(point)
                    this.$userData.adminPoints += parseInt(point)
                })

                this.$eventBus.$on('SEND_MESSAGE', message => {
                    this.messages.push({
                        type: 'me',
                        message: message,
                    })
                    localStorage.setItem('messages', JSON.stringify(this.messages))
                    this.socket.emit('message', message)
                })


            },
            startTimeCountDown() {
                if (this.timeCountDown) {
                    clearInterval(this.timeCountDown)
                    this.timeCountDown = null
                }

                this.timeCountDown = setInterval(_ => {
                    this.timeRemaining --

                    if (this.timeRemaining < 1) {
                        this.setGameOver()
                        return
                    }

                    if (!this.timeWaring && this.timeRemaining <= 600) {
                        this.playAudio(22)
                        this.timeWaring = true
                    }
                }, 1000)
            },
            async sendStartDateTime() {
                const startDateTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')

                const params = {
                    startDateTime: startDateTime
                }
                try {
                    await this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/trainee/start`, params)
                    await localStorage.setItem('startDateTime', startDateTime)
                } catch (e) {}

                return startDateTime
            },
            setGameOver() {

                clearInterval(this.timeCountDown)
                this.timeCountDown = null


                this.$store.commit('hideAlert')
                setTimeout(_ => {
                    this.$store.commit('showAlert', {
                        message: '게임이 종료되었습니다!\n게임 결과 화면 이동을 위해\n아래 {https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_ok_small.png}을 눌러주세요.',
                        buttons: [
                            {
                                image: `https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_ok.png`,
                                cb: _ => {

                                    this.playAudio(23)

                                    const point = this.$userData.points
                                    let result = 'c'

                                    if (point >= 700) result = 's'
                                    else if (point >= 550) result = 'a'
                                    else if (point >= 400) result = 'b'

                                    this.result = result
                                }
                            },
                            // {
                            //     text: '게임데이터 초기화',
                            //     cb: _ => {
                            //         localStorage.clear()
                            //         this.$router.go(this.$router.currentRoute)
                            //     }
                            // }
                        ]
                    })
                })
            },
            moveToOutro() {
                console.log('move to outro')
                this.$store.commit('hideAlert')
                this.$router.push('/outro')
            },
            replaceUserName(name) {
                for (const [key, value] of Object.entries(this.$gameData.names)) {
                    const regexp = new RegExp(`\\[${key}\\]`, 'g')
                    name = name.replace(regexp, value)
                }

                return name
            },
            openAndShowNote() {
                this.openNote = false

                this.openNote = true
                this.openNoteDetail = false
                this.showNote = true
                this.showNoteDetail = true
            },
            changeMapId(mapId) {
                if ([1,2,3].indexOf(parseInt(mapId)) > -1) {
                    this.playAudio('')
                }
                this.openTeamDetail = false
                this.openProject = false
                this.openQuiz = false
                this.showMatchButton = false
                this.showArrangeButton = false
                this.showSubmitButton = false
                this.$gameData.mapData = this.$gameData.maps[mapId]
                this.$eventBus.$emit('FLOOR')
                this.$eventBus.$emit('HINT_INIT')
                this.mode = 'map'
            },
            onMapChanged(mapData) {
                this.$gameData.mapData = mapData
            },
            moveToTeam() {
                this.mode = 'team'
                this.openProject = false
                this.openQuiz = false
                this.showArrangeButton = false
                this.showSubmitButton = false
                this.$eventBus.$emit('HINT_INIT')
            },
            moveToMission() {
                this.mode = 'mission'
                this.showMatchButton = false
                this.openTeamDetail = false
                this.$eventBus.$emit('HINT_INIT')
            },
            tryMatch() {
                if (!this.canMatch) return
                this.$eventBus.$emit('TRY_MATCH')
            },
            submit() {
                if (!this.canSubmit) return
                this.$eventBus.$emit('SUBMIT')
            },
            arrange() {
                if (!this.canArrange) return
                this.$eventBus.$emit('ARRANGE')
            },
            goBack() {
                if (this.mode === 'map') {
                    $('.map-wrap').fadeOut(200, _ => {
                        this.changeMapId(this.$gameData.mapData.parent_id)
                        this.$eventBus.$emit('FLOOR')
                        this.mode = 'map'
                        $('.map-wrap').fadeIn(200)
                    })
                } else if (this.mode === 'team' && this.openTeamDetail) {
                    this.openTeamDetail = false
                    this.showMatchButton = false
                    this.canMatch = false
                } else if (this.openQuiz) {
                    this.openQuiz = false
                    this.showSubmitButton = false
                    this.canSubmit = false
                } else if (this.openProject) {
                    this.openProject = false
                    this.showArrangeButton = false
                    this.canSubmit = false
                } else {
                    if (!this.$gameData.hintItem || (this.$gameData.hint && this.$gameData.hint.items && !this.$gameData.hint.items[0].name)) {
                        setTimeout(_ => {
                            // this.mode = 'map'
                            this.changeMapId(this.$gameData.mapData.id)
                        }, 200)
                        this.$eventBus.$emit('HINT_INIT')
                        if (!this.showNote) this.showNote = true
                        if (!this.showNoteDetail) this.showNoteDetail = true
                    } else {
                        this.$eventBus.$emit('HINT_INIT')
                    }
                }
            },

            saveUserData() {
                const params = {
                    userData: JSON.stringify(this.$userData)
                }
                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/trainee/userdata`, params).then(response => {
                })
            },

            logout() {

                this.$store.commit('showAlert', {
                    message: 'KB C.S.I에서 로그아웃 하시겠습니까?',
                    buttons: [
                        {
                            image: 'https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_cancel.png',
                            cb: _ => {
                                this.$store.commit('hideAlert')
                            }
                        },
                        {
                            image: 'https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_logout_confirm.png',
                            cb: _ => {
                                this.$eventBus.$emit('STOP_ALL')

                                localStorage.removeItem('startDateTime')
                                localStorage.removeItem(`message`)

                                this.$cookie.delete('token')
                                this.$cookie.delete('training_id')
                                this.$cookie.delete('id')
                                this.$cookie.delete('name')

                                this.$router.replace('/')
                                this.$store.commit('hideAlert')

                            }
                        },
                    ]
                })
            }

        }
    }
</script>

<style lang="scss" scoped>

    img { max-width: 100%; }

    .nav-top {
        padding: 4px 4px 4px 0px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        pointer-events: visible;

        &>* { line-height: 0}

        .label { width: 105px;}

        .btn-help { width: 35px;}
        .btn-message {
            width: 35px;
            position: relative;

            .icon-new {
                position: absolute;
                width: 15px; height: 15px;
                right: -5px;
                top: -5px;
            }
        }
        .btn-keywords { width: 70px; }
        .btn-hint { width: 35px; }
        .btn-logout { width: 35px; }

        .time, .point {
            width: 85px;
            height: 37.5px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            font-size: 10px;
            font-weight: 400;
            font-family: KBFGDisplayB;
            color: #fff;
            line-height: 1.5;

            p {
                padding: 11px 0 0 35px;
                text-align: center;
            }
        }

        .time {
            background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_time.png);

            &.warn {
                background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_time_warning.png);
            }
        }

        .point {
            background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_point.png);
        }
    }

    .nav-bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 10;

        &>* { line-height: 0}

        .btn-map, .btn-match, .btn-submit, .btn-arrange {
            width: 100px;
        }

        .btn-team {
            width: 100px;
            position: relative;

            .icon-new {
                position: absolute;
                width: 15px; height: 15px;
                right: -5px;
                top: -5px;
            }
        }

        .btn-mission {
            width: 100px;
            position: relative;

            .icon-new {
                position: absolute;
                width: 15px; height: 15px;
                right: -5px;
                top: -5px;
            }
        }
    }

    .nav-left {
        position: absolute;
        width: 39px;
        top: 65px;
        left: 16px;
        z-index: 10;

        img + img { margin-top: 5px;}
    }

    .qr-reader {
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        z-index: 100;
    }

    .popup-layout {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 500;
    }

    .result {
        position: fixed;
        top: 0; bottom: 0;
        left: 0; right: 0;
        background-color: #787067;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5000;
    }


</style>

