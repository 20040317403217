<template>
	<v-sheet class="popup-layout" width="100%" height="100%">
		<v-sheet class="popup-wrap" width="100%" height="100%">
			<div class="popup-title"
			     :style="{fontSize: `${40*$gameData.scale}px`, height: `${60*$gameData.scale}px`}"
			>
				관리자와 메시지
				<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="v => {$emit('close'); playAudio(1)}" />
			</div>
			<div class="body">
				<div class="message-body d-flex flex-column-reverse">
					<div class="message-wrap"
					     v-for="(message, index) in messages"
					     :key="index"
					     :class="message.type"
					>
						<div v-if="!message.type" class="admin">관리자</div>
						<div class="message">
							{{ message.message }}
						</div>
					</div>
				</div>
				<div class="input-box">
					<textarea class="input" placeholder="메시지 입력 칸"
					          @keydown.ctrl.enter="send"
					          v-model="message"
					></textarea>
					<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_send.png" @click="send" />
				</div>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<script>
	export default {
		data() {
			return {
				messages: [],
				message: '',
			}
		},
		mounted() {
			this.getList()

			this.$eventBus.$emit('NEW_MESSAGE', false)

			this.$eventBus.$on('RECEIVED_MESSAGE', _ => {
				this.getList()
			})
		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			getList() {
				const self = this

				this.messages = JSON.parse(localStorage.getItem('messages') || '[]').reverse()
			},

			send() {
				if (!this.message) return

				this.playAudio(4)

				this.$eventBus.$emit('SEND_MESSAGE', this.message)
				this.message = ''

				this.getList()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.popup-layout {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3%;
		background-color: #3d3d3d;
		z-index: 500;

		.popup-wrap {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			border: solid 5px #febd36;
			background-color: #ffe5c0;
			display: flex;
			flex-direction: column;

			.popup-title {
				height: 45px;
				background-color: #febd36;
				font-weight: 400;
				font-family: KBFGDisplayB;
				font-size: 25px;
				color: #f9f9f9;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					height: 80%;
					position: absolute;
					right: 1%;
					top: 1%;
				}
			}

			.body {
				padding: 1%;
				height: 100%;
				overflow: hidden;
				position: relative;
			}

			.message-body {
				overflow-y: scroll;
				height: 100%;
				padding-bottom: 85px;
			}

			.message-wrap {
				color: #787067;
				font-size: 15px;
				margin: 1%;

				.admin {
					font-weight: bold;
				}
				.message {
					display: inline-block;
					border-radius: 0px 5px 5px 5px;
					background-color: #ffffff;
					padding: 1%;
					max-width: 45%;
					word-break: keep-all;
				}

				&.me {
					text-align: right;

					.message {
						background-color: #787067;
						color: #ffffff;
						border-radius: 5px 0 5px 5px;
					}

				}
			}

			.input-box {
				background-color: #ffffff;
				height: 85px;
				max-height: 30%;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				display: flex;
				align-items: center;

				.input {
					width: calc(87%); height: 100%;
					resize: none;
					font-size: 15px;
					color: #787067;
					padding: 1%;
				}

				img {
					position: absolute;
					height: 70%;
					right: 2%;
				}
			}

		}
	}
</style>
<style>
	img {
		max-width: 100%;
	}
</style>
