<template>
	<v-layout class="project-wrap" align-center justify-center>
		<div class="label">
			<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/label.png" />
			<span>Project {{project.id}}</span>
		</div>
		<div ref="project" class="project"
		     :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}">

			<div class="background"
				 :style="{
					left: `${60*$gameData.scale}px`,
					top: `${115*$gameData.scale}px`,
					width: `${745*$gameData.scale}px`,
				 }"
				>
				<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_project_txt${project.id}.png`" />
				<!--
				<div class="project-desc"
				     :style="{
							left: `${43*$gameData.scale}px`,
							top: `${62*$gameData.scale}px`,
							width: `${270*$gameData.scale}px`,
							height: `${217*$gameData.scale}px`,
							fontSize: `${18*$gameData.scale}px`,
						 }"
				>
					<p class="project-title"
					   :style="{
							fontSize: `${31*$gameData.scale}px`,
						 }"
					>
						지역사회혁신 프로젝트
					</p>
					지역사회 문제해결을 통한
					지역공동체 강화를 위하여
					노력하는 사회적기업 대상으로
					‘제품 개발’ 또는 ‘연구 활동’을
					지원하여 일자리 창출에 기여
				</div>
				-->
			</div>
			<div class="project-body"
			     :style="{
							left: `${457*$gameData.scale}px`,
							top: `${150*$gameData.scale}px`,
							width: `${757*$gameData.scale}px`,
							height: `${420*$gameData.scale}px`,
							boxShadow: `${10*$gameData.scale}px ${10*$gameData.scale}px rgba(61,61,61,0.2)`,
							borderRadius: `${10*$gameData.scale}px`,
							padding: `${18*$gameData.scale}px ${15*$gameData.scale}px`,
						 }"
			>
				<div class="body-label"
				     :style="{
							fontSize: `${25*$gameData.scale}px`,
							padding: `${8*$gameData.scale}px`,
							borderTopLeftRadius: `${10*$gameData.scale}px`,
							borderTopRightRadius: `${10*$gameData.scale}px`,
						 }"
				>
					필요한 강점 ({{ strengths.length }}개)
				</div>
				<div class="strengths"
				     :style="{
						padding: `${20*$gameData.scale}px`,
						borderBottomLeftRadius: `${10*$gameData.scale}px`,
						borderBottomRightRadius: `${10*$gameData.scale}px`,
				     }"
				>
					<div v-for="(n, index) in 3">
					     <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/${strengths[index] ? 'icon_strength' + strengths[index] : 'btn_match_team_disable'}.png`" />
					</div>
				</div>
				<div class="body-label"
				     :style="{
							fontSize: `${25*$gameData.scale}px`,
							padding: `${8*$gameData.scale}px`,
							marginTop: `${15*$gameData.scale}px`,
							borderTopLeftRadius: `${10*$gameData.scale}px`,
							borderTopRightRadius: `${10*$gameData.scale}px`,
						 }"
				>
					필요한 팀원 ({{ strengths.length }}명)
				</div>
				<div class="characters"
				     :style="{
						padding: `${20*$gameData.scale}px`,
						borderBottomLeftRadius: `${10*$gameData.scale}px`,
						borderBottomRightRadius: `${10*$gameData.scale}px`,
				     }"
				>
					<div v-for="(n, index) in 3"
					     @click="onTeamClicked(index)"
					>
						<img v-if="!strengths[index]" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match_team_disable.png" />
						<img v-else-if="characters[index]" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_matched_team${characters[index]}.png`" />
						<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match_team.png" />
					</div>
				</div>
			</div>

		</div>
	</v-layout>
</template>

<script>
	export default {
		data() {
			return {
				characters: [],
			}
		},

		props: [
			'project'
		],

		computed: {
			strengths() {
				const strengths = []
				strengths.push(this.project.strength1)
				if (this.project.strength2) strengths.push(this.project.strength2)
				if (this.project.strength3) strengths.push(this.project.strength3)

				return strengths
			},
		},

		beforeDestroy() {
			this.$eventBus.$off('ARRANGE')
		},

		mounted() {
			this.$eventBus.$on('ARRANGE', _ => {
				let success = true

				for (let i = 1; i <= 3; i++) {

					const correct = this.project[`character${i}`]
					if (correct && this.characters.indexOf(correct) === -1) {
						success = false
					}
				}

				if (success) {
					this.playAudio(18)
					const points = [0, 40, 60, 80]
					this.$store.commit('showAlert', {
						message: `PJT가 성공적으로 완료되었습니다.\n[${points[this.project.difficult]}] 포인트 획득`,
						okCb: _ => {
							this.$eventBus.$emit('ON_ARRANGE', points[this.project.difficult])
						}
					})
				} else {
					this.playAudio(19)
					this.$store.commit('showAlert', {
						message: 'PJT 팀원 배치에 실패하였습니다.\n다시 도전해주세요!',
					})
				}


			})
		},

		methods: {
			playAudio(id) {
				this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
			},
			onTeamClicked(index) {

				this.playAudio(1)

				const currentCharacter = this.characters[index]

				this.$eventBus.$off('ON_SELECT_CHARACTER')
				this.$eventBus.$once('ON_SELECT_CHARACTER', imgNum => {

					if (currentCharacter === imgNum) return;

					if (!this.$userData.strengthCompleted[imgNum]) {
						this.$store.commit('showAlert', {
							message: '강점 매칭이 완료되지 않은 팀원입니다.',
						})
						return
					}

					let isDup = false
					this.characters.forEach(character => {
						if (character === imgNum) {
							isDup = true
						}
					})

					if (isDup) {
						this.$store.commit('showAlert', {
							message: '이미 PJT에 배치된 팀원입니다.',
						})
						return
					}

					this.$set(this.characters, index, imgNum)

					if (this.strengths.length === this.characters.length) {
						this.$eventBus.$emit('CAN_ARRANGE', true)
					}
					// this.canMatch()
				})

				this.$eventBus.$emit('OPEN_QR_READER', this.characters[index], 'character')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.project-wrap {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #DBD3CC;

		.label {
			position: fixed;
			left: 0;
			top: 11px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 105px;

			span {
				position: absolute;
				font-size: 17px;
				font-family: KBFGDisplayB;
				color: #ffffff;
			}

		}

		.project {
			display: flex;
			margin: 0;
			position: relative;
			align-items: center;

			.background {
				position: absolute;
				color: #787067;

				.project-desc {
					position: absolute;
					word-break: keep-all;
					text-align: center;
					.project-title {
						font-family: KBFGDisplayB;
						line-height: 1.2;
						margin-bottom: 0;
					}
				}
			}

			.project-body {
				position: absolute;
				background-color: #ffffff;

				.body-label {
					background-color: #787067;
					color: #ffffff;
					text-align: center;
					font-family: KBFGDisplayB;
				}

				.strengths, .characters {
					width: 100%;
					display: flex;
					background-color: #ADA197;

					div {
						flex-grow: 1;
						width: 30%;
						line-height: 0;
					}
					div+div {
						margin-left: 3%;
					}
				}
			}
		}
	}
</style>
