<template>
	<v-layout class="team-wrap" align-center justify-center>
		<div ref="team" class="team"
		     :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
		>
			<v-carousel v-model="carousel" height="100%" hide-delimiters :continuous="false"
			            :show-arrows="false"
			>
				<v-carousel-item
						v-for="(i, idx) in 3"
						:key="idx"
				>
					<v-layout justify-center align-center fill-height
					          v-if="i == 1 || $userData.stage >= i"
					>
						<div class="character"
						     v-for="(no, index) in characterNoArr(i)"
						     v-if="no!=9"
						     :key="index"
						     :style="{marginTop: `${35*$gameData.scale}px`, height: `${450*$gameData.scale}px`,}"
						>
							<div class="avatar"
							     :style="{height: `${70*$gameData.scale}px`, marginTop: `${-35*$gameData.scale}px`}"
							>
								<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/character_${no}.png`" />
							</div>
							<div class="username"
							     :style="{height: `${88*$gameData.scale}px`, fontSize: `${30*$gameData.scale}px`}"
							>
								{{ $gameData.names[`username${no}`]}} {{ $gameData.positions[no]}}
							</div>
							<div class="strength completed"
							     v-if="$userData.strengthCompleted[no]"
							     :style="{padding: `${20*$gameData.scale}px`, height: `81%`}"
							>
								<div
										v-for="(str, index) in ($userData.strengths[no] || [])"
								>
									<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/team_strength${str}.png`" />
								</div>
							</div>
							<div class="strength"
							     v-else
							     :style="{padding: `${20*$gameData.scale}px`, height: `81%`}"
							>
								<div>
									<div
											v-for="(str, index) in ($userData.strengths[no] || [])"
									>
										<img v-if="typeof str === 'number'" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/team_strength${str}.png`" />
										<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/team_strength_none.png" />
									</div>
									<div
											v-for="(str) in 3-($userData.strengths[no] || []).length"
									>
										<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/team_strength_none.png" />
									</div>
									<div class="buttons"
									     :style="{paddingTop: `${10*$gameData.scale}px`}"
									>
										<span>
											<img v-if="countHintOpen(no) >= countHints(no) / 2" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match_strength.png" @click="detail(no)" />
											<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match_strength_disabled.png" />
										</span>
										<span style="position: relative;">
											<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_hint_status.png" />
											<div class="hints"
											     :style="{fontSize: `${22*$gameData.scale}px`}"
											>
												<span>{{ countHintOpen(no) }}</span>/{{ countHints(no) }}
											</div>
										</span>
									</div>
								</div>
							</div>
						</div>
					</v-layout>
					<v-layout justify-center align-center fill-height
					          v-else
					          class="disabled"
					>
						<div class="character"
						     v-for="(no, index) in 3"
						     v-if="i != 3 || no != 3"
						     :key="index"
						     :style="{height: `${450*$gameData.scale}px`,}"
						>
							<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user_unknown.png" />
						</div>
						<div class="comment"
						     :style="{
								width: `${814*$gameData.scale}px`,
								height: `${400*$gameData.scale}px`,
								fontSize: `${50*$gameData.scale}px`,
								marginTop: `${25*$gameData.scale}px`
						     }"
						>
							미션 {{ i === 2 ? 11 : 15}}개 달성 시 공개
						</div>
					</v-layout>
				</v-carousel-item>
			</v-carousel>
			<div class="carousel-buttons"
			     :style="{height: `${77*$gameData.scale}px`}"
			>
				<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_carousel_prev.png" class="btn_prev" v-show="carousel !== 0" @click="carousel--" />
				<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_carousel_next.png" class="btn_next" v-show="carousel !== 2" @click="carousel++" />
			</div>
		</div>
	</v-layout>
</template>

<script>
	export default {
		data() {
			return {
				carousel: 0,
				gameData: this.$gameData,
				userData: this.$userData,
			}
		},

		computed: {
			characterNoArr() {
				return val => [...Array(3).keys()].map(i => i + ((val - 1) * 3 + 1))
			},
			countHintOpen() {
				return no => Object.keys(this.$userData.characterHints[no] || {}).length
			},
			countHints() {
				return no => this.$gameData.characters[no-1] && this.$gameData.characters[no-1].places.length || 0
			}
		},

		mounted() {
			this.$eventBus.$emit('NEW_TEAM', false)
		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			detail(no) {
				this.playAudio(1)
				this.$eventBus.$emit('TEAM_DETAIL', no)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.team-wrap {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;

		.team {
			display: flex;
			margin: 0;
			position: relative;
			align-items: center;

			.carousel-buttons {
				width:100%;
				position: absolute;
				left: 0;
				pointer-events: none;

				img {
					pointer-events: all;
					height: 100%;
					position: absolute;
					margin-left: 3%;
				}
				img + img {
					right: 0;
					margin: 0 3% 0 0;
				}
			}

			.character {
				width: 25%;
				border-radius: 5px;
				border: solid 2.5px #787067;
				background-color: #f7f7f7;
				height: 65%;
				position: relative;

				&+.character {
					margin-left: 1.6%;
				}

				.avatar {
					position: absolute;
					left: 0; top: 0;
					width: 100%;
					text-align: center;

					img { max-height :100%;}
				}

				.username {
					margin: -1px -1px 0 -1px;
					padding-bottom: 2px;
					background-color: #787067;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-family: KBFGDisplayB;
				}
				.strength {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.buttons {
						border-top: 2px dashed #787067;
						display: flex;
						justify-content: space-between;
						margin-bottom: -5%;

						&>span {
							width: 48%;
						}

						.hints {
							position: absolute;
							text-align: center;
							width: 100%;
							top: 42%;
							color: #787067;
							font-family: KBFGDisplayB;

							span {
								color: #F74343;
							}
						}
					}
				}
			}

			.disabled {
				.character {
					border-radius: unset;
					border: unset;
					background-color: unset;
					width: 24%;
				}

				.comment {
					position: absolute;
					background-color: rgba(25, 24, 24, 0.8);
					border-radius: 10px;
					color: #ffffff;
					font-family: KBFGDisplayB;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
</style>
