<template>
    <v-layout class="hint-wrap" align-center justify-center>
        <div ref="hint" class="hint">
            <img class="hint-background" v-if="hint.type === 'T' || (hint.items && !hint.items[0].name) || $gameData.hintItem" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/hint_background.png"
                 :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
            />
            <img class="hint-background" v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/hint_list_background.png"
                 :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
            />
            <v-layout
                    justify-center
                    align-center
                    class="label"
                    :style="`width:${vw*350}px;height:${vh*72}px;left:${vw*80}px;top:${vh*154}px;font-size:${vw*30}px;`"
            ><span>{{ hint.name }}</span></v-layout>
            <div
                    class="face"
                    v-if="hint.type === 'P'"
                    :style="`width:${vw*320}px;left:${vw*95}px;top:${Math.floor(vh*235)}px;`"
            >
                <img v-show="hint.image" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/${hint.image}`" />
            </div>
            <div
                    class="face"
                    v-else
                    :style="`width:${vw*320}px;height:${vh*320}px;left:${vw*95}px;top:${vh*250}px;`"
                    style="align-items: center; justify-content: center"
            >
                <img v-show="hint.image" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/${hint.image}`" />
            </div>
            <div
                    v-if="hint.type === 'T' || (hint.items && !hint.items[0].name) || $gameData.hintItem"
            >
                <div
                        class="questions content text-center"
                        :style="`width:${vw*730}px;height:${vh*300}px;left:${vw*490}px;top:${vh*155}px;font-size:${vw*30}px;padding:${vw*20}px;color:#fff;`"
                        v-html="hintItem ? hintItem.content : (hint.items && hint.items[0].content)"
                >
                </div>
                <div class="questions strength"
                     :style="`width:${vw*730}px;height:${vh*95}px;left:${vw*490}px;top:${vh*470}px;font-size:${vw*32}px;padding:${vw*10}px;`"
                     @click="onClickStrength()"
                >
                    {{ hintItem && hintItem.strength_text || '예상 강점'}} :
                    <img v-if="strengthNo"
                         :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strengthNo}_on.png`"
                         :style="`height: ${vh*75}px`" class="ml-2"
                    />
                    <img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_note_strength.png" :style="`height: ${vh*75}px`" class="ml-2"/>
                    <div class="disabled"
                         v-show="!(hintItem && hintItem.strength_text)"
                         :style="`border-radius: ${vw*10}px`"
                    ></div>
                </div>
            </div>
            <div
                    v-else
                    class="questions justify-center align-center d-inline-flex flex-column"
                    :style="`width:${vw*730}px;height:${vh*410}px;left:${vw*490}px;top:${vh*155}px;`"
            >
                <div class="question-wrap d-inline-block text-center">
                    <v-layout
                            justify-center
                            align-center
                            class="question d-inline-flex"
                            :class="getHintClass(item)"
                            v-for="(item, index) in hint.items"
                            :key="index"
                            :style="`width:${vw*610}px;height:${vh*80}px;font-size:${vw*35}px;margin-top:${vh*8}px;margin-bottom:${vh*8}px;`"
                            @click="setHintItem(item, $event)"
                    >
                        <span>{{ $userData.stage >= item.stage && (!item.condition || $userData.hintItem[item.condition]) ? item.name : '특정 조건 달성 시 열람 가능' }}</span>
                        <div class="disabled"
                             v-show="$userData.hintItem[item.related]"
                             :style="`border-radius: ${vw*10}px`"
                        ></div>
                    </v-layout>
                </div>
            </div>
        </div>
    </v-layout>
</template>

<script>
    export default {
        data() {
            return {
                hint: {},
                hintItem: null,
                gameData: this.$gameData,
                userData: this.$userData,
                characterId: 0,
                strengthNo: 0,
            }
        },

        computed: {
            vw() {
                return this.$gameData.viewWidth / 1280
            },
            vh() {
                return this.$gameData.viewHeight / 720
            }
        },

        watch: {
            '$gameData.hintItem' : {
                handler(val, oldVal) {
                    if (oldVal && oldVal.mission_value) {
                        // console.log('has mission_value', oldVal)
                        this.$eventBus.$emit('CHECK_MISSION', oldVal.mission_value)
                    }
                }
            }
        },
        beforeCreate() {
            const self = this
        },
        beforeDestroy() {
            this.$eventBus.$off('HINT_INIT')
        },
        mounted() {
            const self = this

            this.setHintData()

            this.$eventBus.$on('HINT_INIT', _ => {

                $('.content').scrollTop(0)

                if (!this.$gameData.hintItem) {
                    $('.hint-wrap').fadeOut(200, _ => {
                        self.hint = {}
                    })
                } else {
                    this.$eventBus.$emit('CHECK_MISSION', this.$gameData.hintItem.mission_value)
                }
                this.$gameData.hintItem = null
            })

            window.removeEventListener("orientationchange", this.onResize);
            window.addEventListener("orientationchange", this.onResize, true);

        },
        methods: {

            setHintData() {
                const self = this

                $('.hint-wrap').show()

                const hint = self.$gameData.hint

                for (const [key, value] of Object.entries(self.$gameData.names)) {
                    const regexp = new RegExp(`\\[${key}\\]`, 'g')
                    hint.name = hint.name.replace(regexp, value)

                    hint.items.forEach(item => {
                        item.name = item.name.replace(regexp, value)
                        item.content = item.content.replace(regexp, value)
                        if (item.strength_text) item.strength_text = item.strength_text.replace(regexp, value)
                    })
                }

                hint.items.forEach(item => {
                    item.content = item.content
                            .replace(/\[([^\]]+)\]/g, `<span style='color:#FEBD36;'>$1</span>`)
                            .replace(/\n/g, '<br />')
                })

                self.hint = hint

                if (hint.type === 'T' || (hint.items && !hint.items[0].name)) {
                    this.setHintItem(hint.items[0])
                }
            },
            playAudio(id) {
                this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
            },

            setHintItem(item, event) {
                if (this.$userData.hintItem[item.related]) return;

                this.playAudio(17)

                if (!event || !$(event.currentTarget).hasClass('disabled')) {

                    item.content = item.content.replace(/\n/g, '<br />')
                    this.$gameData.hintItem = item
                    this.$set(this.$userData.hintItem, item.id, true)

                    this.hintItem = item

                    this.$gameData.characters.forEach(character => {
                        character.places.forEach(place => {
                            if (place.hint_item_id === item.id) {
                                if (!this.$userData.characterHints[place.character_id]) {
                                    this.$set(this.$userData.characterHints, place.character_id, {})
                                }
                                this.$set(this.$userData.characterHints[place.character_id], item.id, true)
                                if (place.enable_strength) {
                                    this.characterId = place.character_id
                                }
                            }
                        })
                    })

                    // if (item.strength_text) {
                    //     this.$set(this.$userData.characterHints[this.characterId], item.id, true)
                    // }

                    if (!this.$userData.noteStrengths[this.characterId]) {
                        this.$set(this.$userData.noteStrengths, this.characterId, {})
                        this.$set(this.$userData.noteStrengths[this.characterId], this.characterId, {})
                    }
                    this.strengthNo = this.$userData.noteStrengths[this.characterId][this.hintItem.id]
                }

            },

            getHintClass(item) {

                if (this.$userData.stage >= item.stage) {
                    if (!item.condition || this.$userData.hintItem[item.condition]) {
                        if (this.$userData.hintItem[item.id]) {
                            return 'completed'
                        } else {
                            return 'enabled'
                        }
                    } else {
                        return 'disabled'
                    }
                } else {
                    return 'disabled'
                }
            },

            onClickStrength() {
                if (!(this.hintItem && this.hintItem.strength_text)) return

                this.playAudio(1)

                this.$eventBus.$emit('OPEN_STRENGTH_SELECT', this.$userData.noteStrengths[this.characterId][this.hintItem.id])

                this.$eventBus.$once('ON_SELECT_STRENGTH', imgNum => {
                    this.$eventBus.$emit('CLOSE_STRENGTH_SELECT')

                    this.strengthNo = imgNum
                    if (!this.$userData.noteStrengths[this.characterId]) {
                        this.$set(this.$userData.noteStrengths, this.characterId, {})
                    }
                    this.$set(this.$userData.noteStrengths[this.characterId], this.hintItem.id, imgNum)
                })
            },

        },
    }
</script>

<style lang="scss" scoped>
    .hint-wrap {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #DBD3CC;

        .hint {
            display: inline-block;
            margin: 0 ;
            position: relative;

            .label {
                position: absolute;
                font-size: 30px;
                line-height: 1;
                color: #787067;
                font-weight: 400;
                font-family: KBFGDisplayB,serif !important;

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .face {
                position: absolute;
                text-align: center;
                display: flex;
                align-items: flex-end;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .questions {
                position: absolute;

                &.content {
                    overflow-y: scroll;
                    word-break: keep-all;
                }
                &.strength {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #69625A;
                    font-weight: 400;
                    font-family: KBFGDisplayB,serif !important;

                    .disabled {
                        background-color: rgba(25,24,24,0.8);
                        position: absolute;
                        left: 0; top: 0;
                        width: 100%; height: 100%;
                    }
                }

                .question-wrap {
                    overflow-y: scroll;

                    .question {
                        font-family: KBFGDisplayB !important;
                        font-weight: normal;
                        background-size: 100% 100%;
                        color: #FFFFFF;
                        position: relative;

                        &.enabled {
                            background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/question_background_enabled.png);
                        }
                        &.completed {
                            background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/question_background_completed.png);
                        }
                        &.disabled {
                            background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/question_background_disabled.png);

                            span {
                                padding-left: 10%;
                            }
                        }

                        span {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .disabled {
                            background-color: rgba(25,24,24,0.8);
                            position: absolute;
                            left: 0; top: 0;
                            width: 100%; height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>
