import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=a6ac7db6&scoped=true&"
import script from "./Team.vue?vue&type=script&lang=js&"
export * from "./Team.vue?vue&type=script&lang=js&"
import style0 from "./Team.vue?vue&type=style&index=0&id=a6ac7db6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6ac7db6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VLayout})
