<template>
	<v-sheet class="popup-layout" width="100%" height="100%">
		<v-sheet class="popup-wrap" width="100%" height="100%">
			<div class="popup-title"
			     :style="{fontSize: `${40*$gameData.scale}px`, height: `${60*$gameData.scale}px`}"
			>
				<img class="btn-back" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_popup_back.png" @click="v => {$emit('back'); playAudio(1)}" />
				단서 노트
				<img class="btn-close" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="v => {$emit('close'); playAudio(1);}" />
			</div>
			<div class="body">
				<div class="person"
				>
					<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/user${no}.png`" />
					<div class="name">{{ names[`username${no}`] }}</div>
				</div>
				<div class="detail">
					<div class="line-title label">
						<div class="place">단서 위치</div>
						<div class="strength">예상 강점</div>
					</div>
					<div class="line-title" v-for="(place, index) in character.places"
					     v-if="$userData.hintItem[place.hint_item_id]"
					>
						<div class="place"
						     :style="{height: `${95*scale}px`}"
						     @click="moveToHint(place.hint_id)"
						>
							{{ place.name }}
						</div>
						<div class="strength"
						     @click="onClickStrength(place)"
						>
							<img v-if="!place.enable_strength" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_note_strength_disabled.png" class="disable"
							     :style="{height: `${95*scale}px`}"
							/>
							<img v-else-if="!$userData.noteStrengths[no][place.hint_item_id]" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_note_strength.png"
							     :style="{height: `${95*scale}px`}"
							     />
							<img v-else :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${$userData.noteStrengths[no][place.hint_item_id]}_on.png`"
							     :style="{height: `${95*scale}px`}"
							/>
						</div>
					</div>
					<div class="line-title disabled"
					     v-else
					     :style="{height: `${95*scale}px`}"
					>
						<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_lock.png"
						     :style="{width: `${36*scale}px`}"
						/>
					</div>
				</div>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<script>
	import Vue from 'vue'

	export default {
		data() {
			return {
				strengths: [],
				character: {},
				noteStrength: [],
			}
		},
		props: [
			'no'
		],
		computed: {
			names() {
				return this.$gameData.names
			},
			scale() {
				return this.$gameData.scale
			},
		},
		watch: {
		},
		mounted() {
			this.getList()

			this.character = this.$gameData.characters[this.no - 1]

			if (!this.$userData.noteStrengths[this.no]) {
				this.$set(this.$userData.noteStrengths, this.no, {})
			}

		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			getList() {
				const self = this

/*
				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/strengths`).then(response => {
					if (response.data.code === '0000') {
						self.strengths = response.data.result
					} else {
						self.$store.commit('showSnackbar',{
							message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
							color: 'error',
							duration: 3000
						});
					}
				})
*/
			},
			moveToHint(id) {
				this.playAudio(1)
				this.$eventBus.$emit('HINT_INIT')
				this.$eventBus.$emit('HIDE_NOTE')
				this.$eventBus.$emit('HIDE_NOTE_DETAIL')
				this.$eventBus.$emit('CLOSE_PROJECT')
				this.$eventBus.$emit('CLOSE_QUIZ')
				this.$eventBus.$emit('CLOSE_TEAM_DETAIL')
				this.$eventBus.$emit('OPEN_HINT', id)
			},
			onClickStrength(place) {
				if (!place.enable_strength) return

				this.playAudio(1)

				this.$eventBus.$emit('OPEN_STRENGTH_SELECT', this.$userData.noteStrengths[this.no][place.hint_item_id])

				this.$eventBus.$once('ON_SELECT_STRENGTH', imgNum => {
					this.$eventBus.$emit('CLOSE_STRENGTH_SELECT')

					if (!this.$userData.noteStrengths[this.no]) {
						Vue.observable(this.$userData.noteStrengths, this.no, {})
					}
					this.$set(this.$userData.noteStrengths[this.no], place.hint_item_id, imgNum)

					this.character = {}
					this.character = this.$gameData.characters[this.no - 1]
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.popup-layout {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3%;
		background-color: #3d3d3d;
		z-index: 500;

		.popup-wrap {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			border: solid 5px #febd36;
			background-color: #ffe5c0;
			display: flex;
			flex-direction: column;

			.popup-title {
				height: 45px;
				background-color: #febd36;
				font-weight: 400;
				font-family: KBFGDisplayB;
				font-size: 25px;
				color: #f9f9f9;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				.btn-back {
					height: 80%;
					position: absolute;
					top: 1%;
					left: 1%;
				}

				.btn-close {
					height: 80%;
					position: absolute;
					top: 1%;
					right: 1%;
				}
			}

			.body {
				padding: 3%;
				height: 100%;
				overflow-y: scroll;
				position: relative;


				.person {
					float: left;
					display: inline-block;
					margin: 1% 3% 1% 0;
					position: relative;
					width: 20%;

					.name {
						position: absolute;
						bottom: 6%;
						width: 100%;
						text-align: center;
						color: #ffffff;
						font-size: 20px;
						font-weight: 400;
						font-family: KBFGDisplayB;
					}
				}

				.detail {
					height: 100%;
					float: left;
					width: 77%;

					.line-title {
						display: flex;
						width: 100%;
						align-items: center;
						margin-bottom: 2%;

						&.label {
							margin-bottom: 2%;
							.place, .strength {
								background: #ffffff;
								text-align: center;
								font-size: 13px;
								font-weight: 400;
								font-family: KBFGDisplayB;
								color: #febd36;
								padding: 3px;
								border-top-left-radius: 5px;
								border-top-right-radius: 5px;
								line-height: 1.2;
							}
						}

						&.disabled {
							background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_note_detail_disabled.png);
							background-size: 100% 100%;
							justify-content: center;
						}

						.place {
							flex: 2;
							margin-right: 2%;
							background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_note_place.png);
							background-size: 100% 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 18px;
							font-weight: 400;
							font-family: KBFGDisplayB;
							color: #ffffff;
						}

						.strength {
							flex: 1;
							text-align: center;
							line-height: 0;
						}
					}
				}
			}

		}
	}
</style>
<style>
	img {
		max-width: 100%;
	}
</style>
