import { render, staticRenderFns } from "./AudioWidget.vue?vue&type=template&id=a68aa172&scoped=true&"
import script from "./AudioWidget.vue?vue&type=script&lang=js&"
export * from "./AudioWidget.vue?vue&type=script&lang=js&"
import style0 from "./AudioWidget.vue?vue&type=style&index=0&id=a68aa172&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a68aa172",
  null
  
)

export default component.exports