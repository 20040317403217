var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"team-wrap",attrs:{"align-center":"","justify-center":""}},[_c('div',{ref:"team",staticClass:"team",style:({width: ((_vm.$gameData.viewWidth) + "px"), height: ((_vm.$gameData.viewHeight) + "px")})},[_c('div',{staticClass:"nametag"},[_c('img',{attrs:{"src":("https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/nametag" + _vm.no + ".png")}}),_c('span',{style:({
						fontSize: ((39*_vm.$gameData.scale) + "px"),
					})},[_vm._v(" "+_vm._s(_vm.$gameData.names[("username" + _vm.no)])+" "+_vm._s(_vm.$gameData.positions[_vm.no])+" ")])]),_c('div',{staticClass:"strengths-wrap"},[_c('div',{staticClass:"box-title",style:({
					fontSize: ((27*_vm.$gameData.scale) + "px")
			     })},[_vm._v(" 강점 매칭 ")]),_c('div',{staticClass:"strengths"},_vm._l((_vm.$userData.strengths[_vm.no]),function(num,index){return _c('div',{staticClass:"strength",on:{"click":function($event){return _vm.onClickStrength(index)}}},[(num)?_c('img',{attrs:{"src":("images/btn_strength" + num + "_on.png")}}):_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_note_strength.png"}})])}),0),_c('div',{staticClass:"try-count",style:({
					fontSize: ((27*_vm.$gameData.scale) + "px")
			     })},[_vm._v(" 강점 매칭 시도 횟수 "),_c('span',{style:({
					width: ((110*_vm.$gameData.scale) + "px")
			     })},[_vm._v(_vm._s(this.$userData.matchCounts[this.no]))])]),_c('table',{staticClass:"table-point",style:({
						fontSize: ((23*_vm.$gameData.scale) + "px")
			       }),attrs:{"cellspacing":"1"}},[_c('tr',[_c('th',[_vm._v("시도 횟수")]),_c('th',[_vm._v("1회")]),_c('th',[_vm._v("2회")]),_c('th',[_vm._v("3회")]),_c('th',[_vm._v("4회")]),_c('th',[_vm._v("5회")])]),_c('tr',[_c('td',[_vm._v("획득 가능"),_c('br'),_vm._v("Point")]),_c('td',[_vm._v("50p")]),_c('td',[_vm._v("40p")]),_c('td',[_vm._v("30p")]),_c('td',[_vm._v("20p")]),_c('td',[_vm._v("10p")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }