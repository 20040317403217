<template>
	<v-layout class="quiz-wrap" align-center justify-center>
		<v-layout ref="quiz" class="quiz"
		          justify-center align-center
		     :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
		>
			<div class="quiz-box"
			     :style="{
					width: `${1240*$gameData.scale}px`,
			     }"
			>
				<div class="quiz-title"
				     :style="{
						height: `${80*$gameData.scale}px`,
						fontSize: `${40*$gameData.scale}px`,
					 }"
				>
					{{ quiz.name }}
				</div>
				<div class="quiz-body"
				     :style="{
						padding: `${50*$gameData.scale}px ${30*$gameData.scale}px ${20*$gameData.scale}px`,
						fontSize: `${40*$gameData.scale}px`,
					 }"
				>
					<p class="question"
					   :style="{
						fontSize: `${35*$gameData.scale}px`,
					 }"
					>
						{{ question }}
					</p>
					<v-layout column>
						<v-row
								:style="{
								fontSize: `${35*$gameData.scale}px`
								}"
						>
							<v-col cols="6" class="answer"
							       v-for="n in 4"
							       v-if="quiz[`option${n}`]"
							       @click="onSelectItem(n)"
							>
								<img v-if="answer === n" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_quiz_selected.png" />
								<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_quiz_option.png" />
								<div class="option" :class="answer === n ? 'selected' : ''" :style="`padding-left: ${140*$gameData.scale}px`">
									<span v-html="`&#${9311 + n} ${quiz[`option${n}`]}`"></span>
								</div>
							</v-col>
						</v-row>
					</v-layout>
				</div>
			</div>
		</v-layout>
	</v-layout>
</template>

<script>
	export default {
		data() {
			return {
				answer: 0,
			}
		},

		props: [
			'quiz'
		],

		computed: {
			question() {
				let question = this.quiz.question
				for (const [key, value] of Object.entries(this.$gameData.names)) {
					const regexp = new RegExp(`\\[${key}\\]`, 'g')
					question = question.replace(regexp, value)
				}

				return question
			}
		},

		beforeDestroy() {
			this.$eventBus.$off('SUBMIT')
		},

		mounted() {
			this.$eventBus.$emit('CAN_SUBMIT', false)
			this.$eventBus.$on('SUBMIT', _ => {
				const success = this.answer === this.quiz.correct_answer
				if (success) {
					this.playAudio(18)
					this.$store.commit('showAlert', {
						message: '정답입니다!\n[30 Point] 획득',
						okCb: _ => {
							this.$eventBus.$emit('ON_SUBMIT', success)
						}
					})
				} else {
					this.playAudio(19)
					this.$store.commit('showAlert', {
						message: '오답입니다!\n포인트 획득에 실패하였습니다.',
						okCb: _ => {
							this.$eventBus.$emit('ON_SUBMIT', success)
						}
					})
				}

			})
		},

		methods: {
			playAudio(id) {
				this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
			},
			onSelectItem(n) {
				this.playAudio(1)
				this.answer = n

				this.$eventBus.$emit('CAN_SUBMIT', true)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.quiz-wrap {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #DBD3CC;

		.quiz {
			display: flex;
			margin: 0;
			position: relative;
			align-items: center;

			.quiz-box {
				background-color: #A2C6ED;
				border: 2px solid #A2C6ED;

				.quiz-title {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #A2C6ED;
					color: #ffffff;
					font-family: KBFGDisplayB;
				}

				.quiz-body {
					background-color: #E4ECF7;

					.question {
						font-family: KBFGDisplayB;
						color: #305D9E;
						text-align: center;
					}

					.col-6 {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.option {
							position: absolute;
							color: #305D9E;
							font-family: KBFGDisplayB;

							&.selected {
								color: #ffffff;
							}
						}
					}
				}
			}
		}
	}
</style>
