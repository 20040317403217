var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"quiz-wrap",attrs:{"align-center":"","justify-center":""}},[_c('v-layout',{ref:"quiz",staticClass:"quiz",style:({width: ((_vm.$gameData.viewWidth) + "px"), height: ((_vm.$gameData.viewHeight) + "px")}),attrs:{"justify-center":"","align-center":""}},[_c('div',{staticClass:"quiz-box",style:({
				width: ((1240*_vm.$gameData.scale) + "px"),
		     })},[_c('div',{staticClass:"quiz-title",style:({
					height: ((80*_vm.$gameData.scale) + "px"),
					fontSize: ((40*_vm.$gameData.scale) + "px"),
				 })},[_vm._v(" "+_vm._s(_vm.quiz.name)+" ")]),_c('div',{staticClass:"quiz-body",style:({
					padding: ((50*_vm.$gameData.scale) + "px " + (30*_vm.$gameData.scale) + "px " + (20*_vm.$gameData.scale) + "px"),
					fontSize: ((40*_vm.$gameData.scale) + "px"),
				 })},[_c('p',{staticClass:"question",style:({
					fontSize: ((35*_vm.$gameData.scale) + "px"),
				 })},[_vm._v(" "+_vm._s(_vm.question)+" ")]),_c('v-layout',{attrs:{"column":""}},[_c('v-row',{style:({
							fontSize: ((35*_vm.$gameData.scale) + "px")
							})},_vm._l((4),function(n){return (_vm.quiz[("option" + n)])?_c('v-col',{staticClass:"answer",attrs:{"cols":"6"},on:{"click":function($event){return _vm.onSelectItem(n)}}},[(_vm.answer === n)?_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_quiz_selected.png"}}):_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_quiz_option.png"}}),_c('div',{staticClass:"option",class:_vm.answer === n ? 'selected' : '',style:(("padding-left: " + (140*_vm.$gameData.scale) + "px"))},[_c('span',{domProps:{"innerHTML":_vm._s(("&#" + (9311 + n) + " " + (_vm.quiz[("option" + n)])))}})])]):_vm._e()}),1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }