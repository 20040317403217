import { render, staticRenderFns } from "./Play.vue?vue&type=template&id=4710dcda&scoped=true&"
import script from "./Play.vue?vue&type=script&lang=js&"
export * from "./Play.vue?vue&type=script&lang=js&"
import style0 from "./Play.vue?vue&type=style&index=0&id=4710dcda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4710dcda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VLayout,VMain,VSpacer})
