<template>
	<v-layout column class="intro-main justify-center align-center" fill-height>
		<div class="first-wrap landscape"
			 v-if="mode === 'first' && $gameData.orientation === 'landscape'"
			 :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
		>
			<div class="first-strengths"
				 :style="{
					width: `${905*$gameData.scale}px`,
				}">
				<img class="background" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_final_strength.png" />

				<div class="items" :style="{paddingTop: `${60*$gameData.scale}px`}">
					<div class="strength"
						 :key="index"
						 v-for="(item, index) in strengths.first">
						<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_strength${item}.png`"
							 :style="{
								width: `${232*$gameData.scale}px`,
								margin: `${10*$gameData.scale}px`
							 }"
						/>
					</div>
				</div>
			</div>
			<div class="message"
				 :style="{
					marginTop: `${23*$gameData.scale}px`,
					fontSize: `${40*$gameData.scale}px`
				}"
			>
				<p>
					게임 플레이 후 내가 생각하는 업무 강점이 바뀌었나요?<br />
					<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png" :width="128*$gameData.scale" style="vertical-align: middle; margin-bottom: 4px;" />
					를 눌러 <span class="highlight">나의 대표 강점 3가지</span>를 다시 선택해주세요.
				</p>
			</div>
			<img class="btn-start" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png" :width="210*$gameData.scale"
				 @click="move()"
			/>
		</div>
		<div class="first-wrap portrait"
			 v-if="mode === 'first' && $gameData.orientation === 'portrait'"
			 :style="{width: `${$gameData.viewHeight}px`, height: `${$gameData.viewWidth}px`}"
		>
			<div class="first-strengths"
				 :style="{
					width: `${635*$gameData.scale}px`,
				}">
				<img class="background" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_final_strength_portrait.png" />

				<div class="items" :style="{paddingTop: `${93*$gameData.scale}px`, paddingLeft: `${65*$gameData.scale}px`}">
					<div class="strength"
						 :key="index"
						 v-for="(item, index) in strengths.first">
						<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_strength${item}.png`"
							 :style="{
								width: `${232*$gameData.scale}px`,
								margin: `${10*$gameData.scale}px`
							 }"
						/>
					</div>
				</div>
			</div>
			<div class="message"
				 :style="{
					marginTop: `${23*$gameData.scale}px`,
					fontSize: `${40*$gameData.scale}px`
				}"
			>
				<p>
					게임 플레이 후<br />내가 생각하는 업무 강점이 바뀌었나요?<br />
					<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png" :width="128*$gameData.scale" style="vertical-align: middle; margin-bottom: 4px;" />
					를 눌러 <span class="highlight">나의 대표 강점 3가지</span>를<br />다시 선택해주세요.
				</p>
			</div>
			<img class="btn-start" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png" :width="210*$gameData.scale"
				 :style="{bottom: `${40*$gameData.scale}px`}"
				 @click="move()"
			/>
		</div>
	</v-layout>
</template>

<script>
	import io from "socket.io-client";

	export default {
		data() {
			return {
				mode: '',
				strengthList: [],
				strengths: {
					first: [],
					final: [],
				},
			}
		},

		beforeDestroy() {
			this.socket.close()
		},

		beforeMount() {

			// this.socket = io(process.env.VUE_APP_SOCKET_HOST)
            this.socket = io(process.env.VUE_APP_SOCKET_HOST, {
                transports: ['websocket'],
                secure: true,
            })
            this.setSocketListener()

			this.loadStrengths()

			this.saveUserData()

		},

		mounted() {
		},

		methods: {
			setSocketListener() {
				let self = this
				this.socket.on('connect', data => {
					console.log('connected')

					const trainee = {
						id: this.$cookie.get('id'),
						training_id: this.$cookie.get('training_id'),
						name: this.$cookie.get('name'),
					}

					this.socket.emit('setTrainee', trainee)

				})
				this.socket.on('disconnect', _ => {
					console.log('disconnected')
				})

				this.socket.on('back-to-game', _ => {
					location.replace('/play')
				})

				this.socket.on('finish-game', _ => {
					this.$store.commit('showAlert', {
						message: '게임이 모두 끝났습니다.\n감사합니다!',
						buttons: []
					})
					// this.moveToOutro()
				})
			},

			async loadStrengths() {

				const self = this

				if (this.$gameData.strengths.length == 0) {
					const response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/strengths`)
					this.strengthList = response.data.result
				} else {
					this.strengthList = this.$gameData.strengths
				}
				const strengths = this.strengthList

				const response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/trainee/strength`)
				const strength = response.data.result.first_sum

				strengths.forEach(item => {
					if (strength & item.value) {
						self.strengths.first.push(item.image_num)
					}
				})

				self.mode = 'first'
			},

			move() {
				this.$router.push('/strengths/final')
			},

			saveUserData() {
				const params = {
					userData: JSON.stringify(this.$userData)
				}
				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/trainee/userdata`, params)
						.then(response => {
						})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.intro-main {
		text-align: center;
	}
	.first-wrap {
		display: flex;
		margin: 0;
		position: relative;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.message {
			max-width: 100%;
			margin: 0 auto;
			font-size: 20px;
			color: #787067;
			line-height: 1.5;

			p {
				margin-bottom: 23px;
			}

			.name {
				font-weight: 400;
				font-family: KBFGDisplayB;
			}

			.highlight {
				font-weight: 400;
				font-family: KBFGDisplayB;
				background-color: #FEBD36;
				padding: 1px;
			}

			.help {
				display: inline-block;
				padding: 0 10px;
				background-color: #787067;
				border-radius: 5px;
				color: #DBD3CC;
			}
		}
		.btn-start {margin-top: 10px; display: inline-block}
	}

	.first-strengths {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		.background {
		}

		.items {
			width: 100%;
			position: absolute;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			line-height: 0;

			.strength {
			}
		}
	}

	.portrait {

		.items {
			justify-content: flex-start;
			align-items: flex-start;
		}

		.btn-start {
			position: absolute;
		}
	}

</style>
