import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'

import lodash from 'lodash'
import VueLodash from "vue-lodash";

import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import eventBus from './plugins/EventBus'
import store from './plugins/Store'
import gameData from "./plugins/GameData";

import VueResizeText from 'vue-resize-text';
import VueQrcodeReader from "vue-qrcode-reader";

import moment from 'moment'
import vueMoment from 'vue-moment'
import './registerServiceWorker'


// Vue.filter('vh', function (value) {
// 	if (!value) return ''
//
// 	return value/720*100
// })
//
// Vue.filter('vw', function (value) {
// 	if (!value) return ''
//
// 	return value/1280*100
// })

global.jQuery = require('jquery');
const $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.use(eventBus)
Vue.use(gameData)
Vue.use(VueResizeText)
Vue.use(VueQrcodeReader);

Vue.use(vueMoment, moment)

window.vm = new Vue({
	vuetify,
	eventBus,
	router,
	store,
	gameData,
	render: h => h(App),
}).$mount('#app')


