<template>
    <v-layout column class="intro-main justify-center align-center" fill-height>
        <div class="message-wrap">
            <div class="message"
                 :style="{fontSize: `${35*$gameData.scale}px`}"
            >
                <p><span class="name"
                         :style="{fontSize: `${40*$gameData.scale}px`}"
                >{{name}}</span> 님</p>
                <p><span class="highlight">KB C.S.I</span>에 접속하신 것을 환영합니다.</p>
                <p>팀원들의 <span class="highlight">강점에 대한 단서</span>들을 바탕으로<br /><span class="highlight">강점을 파악</span>하여 높은 포인트를 기록하세요!</p>
                <p class="help">*기기를 가로로 돌려 게임을 진행해주세요.</p>
            </div>
            <v-img class="btn-start" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_start.png" :width="150*$gameData.scale" @click="v => {$router.push('/strengths/first'); playAudio(1)}" />
        </div>
    </v-layout>
</template>

<script>
    export default {
    	data() {
    		return {
    			name: '',
            }
        },

        mounted() {
        },

        beforeMount() {
    		this.name = this.$cookie.get('name')
        },

        beforeRouteEnter(to, from, next) {

            next(vm => {
                const params = {
                    progress: vm.$route.path,
                }

                vm.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/progress`, params)
            })
        },

        methods: {
    	    playAudio(id) {
                this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
            }
        }
    }
</script>


<style lang="scss" scoped>

    .intro-main {
        text-align: center;
    }
    .message-wrap {
        max-width: 100%;
        .message {
            width: 380px;
            max-width: 100%;
            margin: 0 auto;
            font-size: 20px;
            color: #787067;
            line-height: 1.5;

            p {
                margin-bottom: 23px;
            }

            .name {
                font-weight: 400;
                font-family: KBFGDisplayB;
            }

            .highlight {
                font-weight: 400;
                font-family: KBFGDisplayB;
                background-color: #FEBD36;
                padding: 1px;
            }

            .help {
	            display: inline-block;
                padding: 0 10px;
                background-color: #787067;
                border-radius: 5px;
                color: #DBD3CC;
            }
        }
        .btn-start {margin-top: 10px; display: inline-block}
    }
</style>
