var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"mission-wrap",attrs:{"align-center":"","justify-center":""}},[_c('v-layout',{directives:[{name:"touch",rawName:"v-touch:prevent",value:({
				up: function (_) { _vm.current = Math.max(0, _vm.current-1) },
				down: function (_) { _vm.current = Math.min(4, _vm.current+1) },
	          }),expression:"{\n\t\t\t\tup: _ => { current = Math.max(0, current-1) },\n\t\t\t\tdown: _ => { current = Math.min(4, current+1) },\n\t          }",arg:"prevent"}],ref:"mission",staticClass:"mission",style:({width: ((_vm.$gameData.viewWidth) + "px"), height: ((_vm.$gameData.viewHeight) + "px")}),attrs:{"column":""}},[_c('transition-group',{staticClass:"d-flex align-center justify-center flex-column-reverse fill-height",attrs:{"name":"fade","mode":"in-out","duration":"50"}},_vm._l((Object.entries(_vm.$gameData.missions)),function(ref,index){
	          var key = ref[0];
	          var level = ref[1];
return (_vm.current === index || _vm.current === index - 1)?_c('div',{key:index,staticClass:"level",style:({
							marginTop: ("-" + (50*_vm.$gameData.scale) + "px")
						 })},[_c('v-layout',{staticClass:"missions"},_vm._l((Object.entries(level)),function(ref,idx){
						 var id = ref[0];
						 var mission = ref[1];
return _c('div',{staticClass:"item",style:({
							width: ((189*_vm.$gameData.scale) + "px"),
							height: ((223*_vm.$gameData.scale) + "px"),
							margin: ("0 " + (5*_vm.$gameData.scale) + "px")
						 }),on:{"click":function($event){return _vm.onItemClicked(mission)}}},[(_vm.$userData.missionOpened[mission.level][mission.id])?_c('div',{staticClass:"content-wrap"},[(mission.project)?_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_pjt.png"}}):(mission.quiz)?_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_quiz.png"}}):_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_active.png"}}),(mission.project)?_c('div',{staticClass:"desc",style:({
									fontSize: ((23*_vm.$gameData.scale) + "px"),
									color: '#BC8B36',
									marginTop: ((15*_vm.$gameData.scale) + "px"),
							     })},[_vm._v(" "+_vm._s(mission.name)+" ")]):(mission.quiz)?_c('div',{staticClass:"desc",style:({
									fontSize: ((23*_vm.$gameData.scale) + "px"),
									color: '#BC8B36',
									marginBottom: ((15*_vm.$gameData.scale) + "px"),
							     })},[_vm._v(" "+_vm._s(mission.name)+" ")]):_c('div',{staticClass:"desc",style:({
									fontSize: ((25*_vm.$gameData.scale) + "px")
							     })},[_vm._v(" "+_vm._s(mission.name)+" ")]),(_vm.$userData.missionCompleted[mission.level][mission.id])?_c('div',{staticClass:"completed"},[_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_completed.png"}})]):_vm._e(),_c('div',{staticClass:"point",style:({
									fontSize: ((13*_vm.$gameData.scale) + "px")
							     })},[_vm._v(" "+_vm._s(mission.point)+"p ")])]):_c('div',[_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_disabled.png"}})])])}),0)],1):_vm._e()}),0)],1),_c('v-slider',{staticClass:"slider",attrs:{"min":"0","max":"4","vertical":"","hide-details":"","height":"100%","track-color":"#C4BCB5","track-fill-color":"#C4BCB5","thumb-color":"#ffffff"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}),_c('v-layout',{staticClass:"minimap",attrs:{"reverse":"","column":"","align-center":""}},[_vm._l((Object.entries(_vm.$gameData.missions)),function(ref,index){
							     var key = ref[0];
							     var level = ref[1];
return _c('div',{key:index,staticClass:"level",style:({
		     	    marginTop: ((-3*_vm.$gameData.scale) + "px")
						 })},[_c('v-layout',{staticClass:"missions"},_vm._l((Object.entries(level)),function(ref,idx){
						 var id = ref[0];
						 var mission = ref[1];
return _c('div',{staticClass:"item",style:({
							width: ((22*_vm.$gameData.scale) + "px"),
							height: ((25*_vm.$gameData.scale) + "px"),
							margin: ("0 " + (2*_vm.$gameData.scale) + "px")
						 })},[(_vm.$userData.missionOpened[mission.level][mission.id])?_c('div',[(_vm.$userData.missionCompleted[mission.level][mission.id])?_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_completed.png"}}):(mission.project || mission.quiz)?_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_pjt.png"}}):_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_active.png"}})]):_c('div',[_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_disabled.png"}})])])}),0)],1)}),_c('div',{staticClass:"indicator",style:({
				height: ((55*_vm.$gameData.scale) + "px"),
				top: (((5-_vm.current)*11-11) + "px"),
		     })})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }