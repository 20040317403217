<template>
	<v-layout class="team-wrap" align-center justify-center>
		<div ref="team" class="team"
		     :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
		>
			<div class="nametag">
				<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/nametag${no}.png`" />
				<span
						:style="{
							fontSize: `${39*$gameData.scale}px`,
						}"
				>
					{{ $gameData.names[`username${no}`]}} {{ $gameData.positions[no]}}
				</span>
			</div>
			<div class="strengths-wrap">
				<div class="box-title"
				     :style="{
						fontSize: `${27*$gameData.scale}px`
				     }"
				>
					강점 매칭
				</div>
				<div class="strengths">
					<div class="strength"
					     v-for="(num, index) in $userData.strengths[no]"
					     @click="onClickStrength(index)"
					     >
						<img v-if="num" :src="`images/btn_strength${num}_on.png`" />
						<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_note_strength.png" />
					</div>
				</div>
				<div class="try-count"
				     :style="{
						fontSize: `${27*$gameData.scale}px`
				     }"
				>
					강점 매칭 시도 횟수 <span
					:style="{
						width: `${110*$gameData.scale}px`
				     }"
				>{{ this.$userData.matchCounts[this.no] }}</span>
				</div>
				<table class="table-point"
				       :style="{
							fontSize: `${23*$gameData.scale}px`
				       }"
				       cellspacing="1"
				>
					<tr>
						<th>시도 횟수</th>
						<th>1회</th>
						<th>2회</th>
						<th>3회</th>
						<th>4회</th>
						<th>5회</th>
					</tr>
					<tr>
						<td>획득 가능<br />Point</td>
						<td>50p</td>
						<td>40p</td>
						<td>30p</td>
						<td>20p</td>
						<td>10p</td>
					</tr>
				</table>
			</div>
		</div>
	</v-layout>
</template>

<script>
	export default {

		data() {
			return {
				userData: this.$userData,
			}
		},

		props: [
			'no'
		],

		mounted() {
			if (!this.$userData.strengths[this.no]) {
				this.$set(this.$userData.strengths, this.no, [null, null, null])
			}
			if (!this.$userData.matchCounts[this.no]) {
				this.$set(this.$userData.matchCounts, this.no, 0)
			}

			this.$eventBus.$on('TRY_MATCH', this.tryMatch)

			this.canMatch()
		},
		beforeDestroy() {
			this.$eventBus.$off('TRY_MATCH', this.tryMatch)
		},
		methods: {

			canMatch() {

				let canMatch = true
				this.$userData.strengths[this.no].forEach(strength => {
					if (!strength) canMatch = false
				})

				this.$eventBus.$emit('CAN_MATCH', canMatch)
			},

			onClickStrength(index) {
				this.playAudio(1)
				// this.$eventBus.$emit('OPEN_STRENGTH_SELECT', this.$userData.strengths[this.no][index])
				//
				const currentStrength = this.$userData.strengths[this.no][index]
				this.$eventBus.$off('ON_SELECT_QR_STRENGTH')
				this.$eventBus.$once('ON_SELECT_QR_STRENGTH', imgNum => {

					if (currentStrength === imgNum) return;

					let isDup = false
					this.$userData.strengths[this.no].forEach(strength => {
						if (strength === imgNum) isDup = true
					})

					if (isDup) {
						this.$store.commit('showAlert', {
							message: '이미 추가한 강점입니다.',
						})
						return
					}

					this.$set(this.$userData.strengths[this.no], index, imgNum)

					this.canMatch()
				})

				this.$eventBus.$emit('OPEN_QR_READER', this.$userData.strengths[this.no][index])
			},
			playAudio(id) {
				this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
			},

			tryMatch() {
				const character = this.$gameData.characters[this.no-1]
				const strengths = [character.strength1, character.strength2, character.strength3]

				let matchCounts = this.$userData.matchCounts[this.no] + 1
				this.$set(this.$userData.matchCounts, this.no, matchCounts)

				let countMatched = 0
				this.$userData.strengths[this.no].forEach(strength => {
					const index = strengths.indexOf(strength)
					if (index > -1) {
						strengths.splice(index, 1)
						countMatched++
					}
				})

				if (countMatched === 3) {
					this.playAudio(18)
					this.$set(this.$userData.strengthCompleted, this.no, true)
					let point = Math.max(50 - (matchCounts - 1) * 10, 10)
					this.$userData.points += point
					this.$store.commit('showAlert', {
						message: `강점 파악에 성공했습니다.\n[${point} Point] 획득!`,
					})

					this.$eventBus.$emit('CLOSE_TEAM_DETAIL')
					this.$eventBus.$emit('CHECK_MISSION')
				} else {
					this.playAudio(19)
					const hintRemained = 5 - (this.$userData.usedHint[this.no] || 0)
					const buttons = []

					if (this.$userData.points >= 20) {
						buttons.push(
							{
								image: `https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_hint${hintRemained}.png`,
								cb: _ => {
									this.confirmUseHint()
								}
							}
						)
					}

					buttons.push(
						{
							image: 'https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_confirm.png',
							cb: _ => {
								this.$store.commit('hideAlert')
							}
						}
					)

					this.$store.commit('showAlert', {
						message: `강점 파악에 실패하였습니다.\n(정답 : ${countMatched}/3)`,
						buttons: buttons
					})
				}

			},

			confirmUseHint() {

				if (this.$userData.usedHint[this.no] !== 5) {
					this.$store.commit('hideAlert')

					this.$store.commit('showAlert', {
						message: '힌트를 보기 위해 [20 Point] 차감합니다.\n진행하시겠습니까?',
						buttons: [
							{
								image: 'https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_cancel.png',
								cb: _ => {
									this.$store.commit('hideAlert')
								}
							},
							{
								image: 'https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_confirm.png',
								cb: _ => {
									this.$set(this.$userData.usedHint, this.no, (this.$userData.usedHint[this.no] || 0) + 1)
									this.$userData.points -= 20

									this.showHint()
								}
							},
						]
					})
				}
			},
			showHint() {
				const character = this.$gameData.characters[this.no-1]
				const strengths = [character.strength1, character.strength2, character.strength3]
				const matches = []
				this.$userData.strengths[this.no].forEach(strength => {
					matches.push({
						strength: strength,
						matched: strengths.indexOf(strength) > -1
					})
				})

				this.$store.commit('hideAlert')

				this.$store.commit('showAlert', {
					matches: matches
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.team-wrap {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #DBD3CC;

		.team {
			display: flex;
			margin: 0;
			position: relative;
			align-items: center;

			img {

			}

			.nametag {
				width: 35%;
				padding: 0 6%;
				height: 100%;
				position: relative;

				span {
					position: absolute;
					bottom: 20.5%;
					left: 0;
					width: 100%;
					text-align: center;
					color: #ffffff;
					font-family: KBFGDisplayB;

				}
			}
			.strengths-wrap {
				width: 57%;

				.box-title {
					background-color: #787067;
					border-top-left-radius: 1.5vh;
					border-top-right-radius: 1.5vh;
					padding: 1%;
					text-align: center;
					color: #f9f9f9;
					font-family: KBFGDisplayB;
				}

				.strengths {
					display: flex;
					width: 100%;
					padding: 2.8%;
					background: #ada197;
					margin-top: 0.8%;

					.strength {
						flex-grow: 1;
						line-height: 0;

						&+.strength {
							margin-left: 2.8%;
						}
					}
				}

				.try-count {
					background-color: #ffffff;
					margin-top: 0.8%;
					color: #787067;
					text-align: center;
					border-bottom-left-radius: 1.5vh;
					border-bottom-right-radius: 1.5vh;
					padding: 1%;

					span {
						display: inline-block;
						text-align: center;
						background-color: #787067;
						border-radius: 1.5rem;
						color: #ffffff;
						font-family: KBFGDisplayB;
					}
				}

				.table-point {
					width: 100%;
					text-align: center;
					background-color: #787067;
					border-radius: 1.5vh;
					margin-top: 3.7%;

					th {
						width: 16.6666%;
						background-color: #ada197;
						color: #ffffff;
						font-weight: 400;
						padding: 3% 0;
					}

					td {
						background-color: #ffffff;
						color: #787067;
						padding: 1.5% 0;
						line-height: 1.2;
					}

					th:first-child { border-top-left-radius: 1.3vh}
					th:last-child { border-top-right-radius: 1.3vh}

					td:first-child { border-bottom-left-radius: 1.3vh; background-color: #e2dad5;}
					td:last-child { border-bottom-right-radius: 1.3vh}
				}
			}
		}
	}

</style>
