<template>
    <v-layout class="login-layout" align-center justify-center fill-height>
        <div class="landing">
            <div v-if="device === 'ios'" class="ios">
                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/landing_ios.png" />
            </div>
            <div v-if="device === 'android'" class="android">
                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/landing_android.png"
                     @click="location.href='https://kbcsi.kbplay.co.kr'"
                />
                <div>
                    <img class="btn-install" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_install.png" />
                </div>
            </div>
        </div>
    </v-layout>
</template>

<script>
    export default {
        components: {
        },

        data() {
            return {
                device: 'android',
            }
        },

        beforeDestroy() {
            $(window).off('resize', this.onResize)
        },
        mounted() {

            // 아이폰 A2HS 유도
            const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                // return /iphone|ipad|ipod/.test( userAgent );
                return /iphone|ipod/.test( userAgent );
            }
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

            if (isIos() && !isInStandaloneMode()) {
                this.device = 'ios'
                // $('.a2hs-wrap').slideDown()
                $('.landing').show();
            }



            let deferredPrompt;
            // 안드로이드 앱설치 유도
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                deferredPrompt = e;

                $('.landing').show()


                this.device = 'android'

                setTimeout(_ => {

                    $('.btn-install').click(_ => {
                        // Show the prompt
                        deferredPrompt.prompt();
                        // Wait for the user to respond to the prompt
                        deferredPrompt.userChoice.then((choiceResult) => {
                            if (choiceResult.outcome === 'accepted') {
                                console.log('User accepted the A2HS prompt');
                                deferredPrompt = null;
                                $('.a2hs-wrap').hide()
                            } else {
                                console.log('User dismissed the A2HS prompt');
                                location.reload()
                            }
                        });
                    })
                })
            });

            $(window).resize(this.onResize).resize()

        },

        methods: {
            onResize() {
                $('.landing').height(window.innerHeight)
            },

        }
    };
</script>

<style scoped lang="scss">
    .login-layout {
        width: 55vw;
        margin: 0 auto;
    }

    .logo-title {
        margin-left: -8%;
        margin-right: -8%;
    }

    .login-form {
        display: none;
        margin: 0 20%;
    }

    .input-field {
        height: 35px;
        background-color: #F4F0ED !important;
        border: unset !important;
        font-size: 16px;
    }

    .v-text-field--outlined >>> fieldset {
        border-width: 0 !important;
        background-color: #F4F0ED !important;
    }
    @media screen and (min-width: 1024px) {

        .login-form {
            width: 400px;
            padding-bottom: 100px;
        }
    }

    .a2hs-wrap {
        position: fixed;
        bottom: 0;
        left: 0; right: 0;
        display: none;

        &.ios {
            bottom: calc(30*100vw/720);
        }

        .a2hs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;
            background-color: #f2f8ff;
            border-radius: 10px;

            &>img {
                width: calc(90*100vw/720);
                border-radius: 7px;
            }

            .message {

                word-break: keep-all;
                padding-left: 10px;
                font-size: calc(30*100vw/720);

                img {
                    width: calc(25*100vw/720);
                }
            }

            &.ios:after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: calc(-30*100vw/720);
                border-width: calc(30*100vw/720);
                border-style: solid;
                border-color: #f2f8ff transparent transparent transparent;
            }
        }
    }

    .landing {
        display: none;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background-color: #DBD3CC;

        &>div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .ios {
            align-items: flex-end;
        }

        .android {
            align-items: center;

            display: flex;
            justify-content: center;
            align-items: flex-start;

            &>div {
                position: absolute;
                width: calc(425*(100vmin/720));
                top: 50%;

                img {
                    margin-top: calc(188*100vmin/720);
                }
            }
        }
    }
</style>

<style>
    .theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
        background-color: #F4F0ED !important;
    }
</style>
