<template>
	<v-sheet class="popup-layout" width="100%" height="100%">
		<v-sheet class="popup-wrap" width="100%" height="100%">
			<div class="popup-title"
			     :style="{fontSize: `${40*$gameData.scale}px`, height: `${60*$gameData.scale}px`}"
			>
				강점 키워드
				<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="v => {$emit('close'); playAudio(1);}" />
			</div>
			<div class="body">
				<v-layout class="strength"
				          :class="`category${strength.category_id}`"
				          align-center
				          v-for="(strength, index) in strengths"
				          :key="index"
				>
					<div class="strength-name">
						{{ strength.name}}
					</div>
					<v-layout class="strength-description" align-center>
						{{ strength.description }}
					</v-layout>
				</v-layout>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<script>
	export default {
		data() {
			return {
				strengths: [],
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			getList() {
				const self = this

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/strengths`).then(response => {
					if (response.data.code === '0000') {
						self.strengths = response.data.result
					} else {
						self.$store.commit('showSnackbar',{
							message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
							color: 'error',
							duration: 3000
						});
					}
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.popup-layout {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3%;
		background-color: #3d3d3d;
		z-index: 500;

		.popup-wrap {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			border: solid 5px #febd36;
			background-color: #ffe5c0;
			display: flex;
			flex-direction: column;

			.popup-title {
				height: 45px;
				background-color: #febd36;
				font-weight: 400;
				font-family: KBFGDisplayB;
				font-size: 25px;
				color: #f9f9f9;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					height: 80%;
					position: absolute;
					right: 1%;
					top: 1%;
				}
			}

			.body {
				padding: 1%;
				height: 100%;
				overflow-y: scroll;
				position: relative;

				.strength {
					border-radius: 5px;
					font-size: 17px;
					font-weight: 400;
					font-family: KBFGDisplayB;

					&.category1 {
						border: 2px solid #ff8e85;
						background-color: #ff8e85;
					}

					&.category2 {
						border: 2px solid #FCC670;
						background-color: #FCC670;
					}

					&.category3 {
						border: 2px solid #8BCCAB;
						background-color: #8BCCAB;
					}

					&.category4 {
						border: 2px solid #94C1EA;
						background-color: #94C1EA;
					}

					&+.strength {
						margin-top: 1%;
					}

					.strength-name {
						min-width: 20%;
						height: 100%;
						align-items: center;
						text-align: center;
						color: #ffffff;
					}

					.strength-description {
						background-color: #ffffff;
						padding: 1%;
						font-size: 15px;
						color: #787067;
					}
				}
			}

		}
	}
</style>
<style>
	img {
		max-width: 100%;
	}
</style>
