var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"project-wrap",attrs:{"align-center":"","justify-center":""}},[_c('div',{staticClass:"label"},[_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/label.png"}}),_c('span',[_vm._v("Project "+_vm._s(_vm.project.id))])]),_c('div',{ref:"project",staticClass:"project",style:({width: ((_vm.$gameData.viewWidth) + "px"), height: ((_vm.$gameData.viewHeight) + "px")})},[_c('div',{staticClass:"background",style:({
				left: ((60*_vm.$gameData.scale) + "px"),
				top: ((115*_vm.$gameData.scale) + "px"),
				width: ((745*_vm.$gameData.scale) + "px"),
			 })},[_c('img',{attrs:{"src":("https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_project_txt" + (_vm.project.id) + ".png")}})]),_c('div',{staticClass:"project-body",style:({
						left: ((457*_vm.$gameData.scale) + "px"),
						top: ((150*_vm.$gameData.scale) + "px"),
						width: ((757*_vm.$gameData.scale) + "px"),
						height: ((420*_vm.$gameData.scale) + "px"),
						boxShadow: ((10*_vm.$gameData.scale) + "px " + (10*_vm.$gameData.scale) + "px rgba(61,61,61,0.2)"),
						borderRadius: ((10*_vm.$gameData.scale) + "px"),
						padding: ((18*_vm.$gameData.scale) + "px " + (15*_vm.$gameData.scale) + "px"),
					 })},[_c('div',{staticClass:"body-label",style:({
						fontSize: ((25*_vm.$gameData.scale) + "px"),
						padding: ((8*_vm.$gameData.scale) + "px"),
						borderTopLeftRadius: ((10*_vm.$gameData.scale) + "px"),
						borderTopRightRadius: ((10*_vm.$gameData.scale) + "px"),
					 })},[_vm._v(" 필요한 강점 ("+_vm._s(_vm.strengths.length)+"개) ")]),_c('div',{staticClass:"strengths",style:({
					padding: ((20*_vm.$gameData.scale) + "px"),
					borderBottomLeftRadius: ((10*_vm.$gameData.scale) + "px"),
					borderBottomRightRadius: ((10*_vm.$gameData.scale) + "px"),
			     })},_vm._l((3),function(n,index){return _c('div',[_c('img',{attrs:{"src":("https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/" + (_vm.strengths[index] ? 'icon_strength' + _vm.strengths[index] : 'btn_match_team_disable') + ".png")}})])}),0),_c('div',{staticClass:"body-label",style:({
						fontSize: ((25*_vm.$gameData.scale) + "px"),
						padding: ((8*_vm.$gameData.scale) + "px"),
						marginTop: ((15*_vm.$gameData.scale) + "px"),
						borderTopLeftRadius: ((10*_vm.$gameData.scale) + "px"),
						borderTopRightRadius: ((10*_vm.$gameData.scale) + "px"),
					 })},[_vm._v(" 필요한 팀원 ("+_vm._s(_vm.strengths.length)+"명) ")]),_c('div',{staticClass:"characters",style:({
					padding: ((20*_vm.$gameData.scale) + "px"),
					borderBottomLeftRadius: ((10*_vm.$gameData.scale) + "px"),
					borderBottomRightRadius: ((10*_vm.$gameData.scale) + "px"),
			     })},_vm._l((3),function(n,index){return _c('div',{on:{"click":function($event){return _vm.onTeamClicked(index)}}},[(!_vm.strengths[index])?_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match_team_disable.png"}}):(_vm.characters[index])?_c('img',{attrs:{"src":("https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_matched_team" + (_vm.characters[index]) + ".png")}}):_c('img',{attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_match_team.png"}})])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }