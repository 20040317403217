<template>
	<v-sheet class="popup-layout" width="100%" height="100%">
		<v-sheet class="popup-wrap" width="100%" height="100%">
			<div class="popup-title"
			     :style="{fontSize: `${40*$gameData.scale}px`, height: `${60*$gameData.scale}px`}"
			>
				<img v-show="!!selectedItem" class="btn-back" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_popup_back.png" @click="v => {selectedItem = null; playAudio(1)}" />
				도움말
				<img class="btn-close" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="v => {$emit('close'); playAudio(1);}" />
			</div>
			<div class="body">
				<transition-group name="fade" type="in-out">
					<div key="list"
					     v-if="!selectedItem"
					>
						<div class="item"
							 :key="index"
							 :style="{fontSize: `${30*$gameData.scale}px`}"
							 v-for="(item, index) in items"
							 @click="v => {selectedItem = item; playAudio(1)}"
							 >
							{{ item.title }}
						</div>
					</div>
					<div key="detail" class="detail"
						 v-else
					>
						<div class="help-title"
						     :style="{fontSize: `${35*$gameData.scale}px`}"
						>
							{{ selectedItem.title}}
<!--							<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_list.png"-->
<!--							     @click="v => {selectedItem = null; playAudio(1); }"-->
<!--							/>-->
						</div>
						<div class="help-content"
						     :style="{fontSize: `${30*$gameData.scale}px`}"
						>
							<div
								v-if="selectedItem.body"
								v-html="selectedItem.body"
								/>
							<img
									class="attachment"
								v-if="selectedItem.file && selectedItem.file_type === 'image'"
								:src="selectedItem.file"
								/>
							<video
									class="attachment"
								v-if="selectedItem.file && selectedItem.file_type === 'video'"
								:src="selectedItem.file"
								playsinline
								controls
								autoplay
									@play="onPlay"
									@pause="onPause"
							/>
						</div>
					</div>
				</transition-group>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<script>
	export default {
		data() {
			return {
				items: [],
				selectedItem: null,
				muted: false,
			}
		},
		mounted() {
			this.muted = this.$store.getters.isMuted
			this.getList()
		},
		beforeDestroy() {
			this.$store.commit('setMute', this.muted)
		},
		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			getList() {
				const self = this

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/helps`).then(response => {
					if (response.data.code === '0000') {
						self.items = response.data.result
					} else {
						self.$store.commit('showSnackbar',{
							message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
							color: 'error',
							duration: 3000
						});
					}
				})
			},
			onPlay() {
				this.$store.commit('setMute', true)
			},
			onPause() {
				this.$store.commit('setMute', false)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.popup-layout {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 3%;
		background-color: #3d3d3d;
		z-index: 500;

		.popup-wrap {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			border: solid 5px #febd36;
			background-color: #ffe5c0;
			display: flex;
			flex-direction: column;

			.popup-title {
				height: 45px;
				background-color: #febd36;
				font-weight: 400;
				font-family: KBFGDisplayB;
				font-size: 25px;
				color: #f9f9f9;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				.btn-back {
					height: 80%;
					position: absolute;
					top: 1%;
					left: 1%;
				}

				.btn-close {
					height: 80%;
					position: absolute;
					top: 1%;
					right: 1%;
				}
			}

			.body {
				padding: 1%;
				height: 100%;
				overflow: hidden;

				.item {
					object-fit: contain;
					border-radius: 5px;
					background-color: #ffffff;
					margin-bottom: 1%;
					padding: 1%;
					color: #787067;
					font-size: 20px;
					font-weight: 500;
				}

				.detail {
					width: 100%;
					height: 100%;
					border-radius: 5px;
					background-color: #ffffff;
					display: flex;
					flex-direction: column;

					.help-title {
						color: #787067;
						font-size: 20px;
						font-weight: 500;
						position: relative;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding: 1%;

						img {
							height: 80%;
							position: absolute;
							right: 2%;
						}
					}

					.help-content {
						overflow-y: scroll;
						margin: 0 1% 1%;
						padding: 1%;
						border-top: 2px solid #febd36;

						.attachment {
							max-width: 100%;
						}

						video.attachment {
							max-height: 50vh;
						}
					}
				}
			}
		}
	}
</style>
<style>
	img {
		max-width: 100%;
	}
</style>
