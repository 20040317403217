<template>
    <v-layout column class="intro-main justify-center align-center text-center" fill-height>
        <div class="strengths-wrap pt-2">
            <div class="strengths">
                <div class="message"
                     :style="{fontSize: `${35*$gameData.scale}px`}"
                >
                    <p v-if="mode === 'first'">
                        다음의 카드를 보고 자신이 생각하는 <br class="portrait" /><span class="highlight">나의 강점 5가지</span>를 선택해주세요.<br />
                        <span class="hint"
                              :style="{fontSize: `${30*$gameData.scale}px`}"
                        >(한번 더 누를 경우 선택 해제)</span>
                    </p>
                    <p v-if="mode === 'final'">
                        게임 플레이 후<br class="portrait" /> 내가 생각하는 업무 강점은 무엇인가요?<br />
                        <span class="highlight">나의 대표 강점 3가지</span>를 선택해주세요.<br />
                    </p>
                </div>
                <div class="buttongroup portrait">
                    <div class="buttons">
                        <div class="button"
                             v-for="(strength, index) in strengths"
                             :key="index"
                             @click="onSelect(strength)"
                        >
                            <v-img
                                    :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_${selectSum & strength.value ? 'on' : 'off'}.png`"
                                    width="100%"
                                    contain
                            />
                        </div>
                    </div>
                </div>
                <div class="buttongroup landscape">
                    <div class="buttons">
                        <div class="button"
                             v-for="(strength, index) in strengths.slice(0,4)"
                             :key="index"
                             @click="onSelect(strength)"
                        >
                            <v-img
                                    :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_${selectSum & strength.value ? 'on' : 'off'}.png`"
                                    width="100%"
                                    contain
                            />
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="button"
                             v-for="(strength, index) in strengths.slice(4,7)"
                             :key="index"
                             @click="onSelect(strength)"
                        >
                            <v-img
                                    :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_${selectSum & strength.value ? 'on' : 'off'}.png`"
                                    width="100%"
                                    contain
                            />
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="button"
                             v-for="(strength, index) in strengths.slice(7,12)"
                             :key="index"
                             @click="onSelect(strength)"
                        >
                            <v-img
                                    :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_${selectSum & strength.value ? 'on' : 'off'}.png`"
                                    width="100%"
                                    contain
                            />
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="button"
                             v-for="(strength, index) in strengths.slice(12,16)"
                             :key="index"
                             @click="onSelect(strength)"
                        >
                            <v-img
                                    :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.image_num}_${selectSum & strength.value ? 'on' : 'off'}.png`"
                                    width="100%"
                                    contain
                            />
                        </div>
                    </div>
                </div>
            </div>
            <v-img class="btn-select mt-2" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength_select_${selectedCount === maxSelection ? 'on' : 'off'}.png`" width="100" @click="onCompleted()" contain />
        </div>

        <div class="preloader">
            <img v-for="num in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]" :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${num}_on.png`" />
            <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength_select_on.png" />
        </div>
    </v-layout>
</template>

<script>
	import io from "socket.io-client";

    export default {
		data() {
			return {
				mode: '',
				strengths: [],
                selectSum: 0,
                selectedCount: 0,
			}
		},

        computed: {
			maxSelection() {
			    return this.mode === 'first' ? 5 : 3
            }
        },

		beforeMount() {

		    if (this.$route.name === 'FirstStrengths') {
		        this.mode = 'first'
            } else {
                this.mode = 'final'

                // this.socket = io(process.env.VUE_APP_SOCKET_HOST)
                this.socket = io(process.env.VUE_APP_SOCKET_HOST, {
                    transports: ['websocket'],
                    secure: true,
                })
                this.setSocketListener()
            }

			const self = this

			this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/strengths`).then(response => {
				this.strengths = response.data.result
			})
				.catch(error => {
					console.log(error)
				})
		},

        beforeDestroy() {
		    if (this.socket) this.socket.close()
            this.$store.commit('hideAlert')
        },

        beforeRouteEnter(to, from, next) {

            next(vm => {
                const params = {
                    progress: vm.$route.path,
                }

                vm.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/progress`, params)
            })
        },


        methods: {
            setSocketListener() {
                let self = this
                this.socket.on('connect', data => {
                    console.log('connected')

                    const trainee = {
                        id: this.$cookie.get('id'),
                        training_id: this.$cookie.get('training_id'),
                        name: this.$cookie.get('name'),
                    }

                    this.socket.emit('setTrainee', trainee)

                })
                this.socket.on('disconnect', _ => {
                    console.log('disconnected')
                })

                this.socket.on('back-to-game', _ => {
                    location.replace('/play')
                })

                this.socket.on('finish-game', _ => {
                    this.$store.commit('showAlert', {
                        message: '게임이 모두 끝났습니다.\n감사합니다!',
                        buttons: []
                    })
                    // this.moveToOutro()
                })
            },

			onSelect(strength) {
			    this.playAudio(3)
				if (this.selectSum & strength.value) {
					this.selectSum -= strength.value
                    this.selectedCount--
                } else {
					if (this.selectedCount >= this.maxSelection) {
						this.$store.commit('showAlert', {
                            message: `강점은 ${this.maxSelection}가지까지만 선택할 수 있습니다.`
                        })
                        return;
                    }
					this.selectSum += strength.value
					this.selectedCount++
				}
            },
            onCompleted() {
				this.playAudio(1)
				if (this.selectedCount !== this.maxSelection) {
					return;
                }
				let params = {}
				params[this.mode] = this.selectSum
				this.$userData.traineeStrengths = this.selectSum


                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/strengths`, params).then(response => {
	            	if (response.data.code === '0000') {

	            	    if (this.mode === 'first') {
                            this.$router.push('/names')
                        }

                    } else {
			            this.$store.commit('showSnackbar',{
				            message: `오류가 발생했습니다. (오류코드 : ${response.data.code})`,
				            color: 'error',
				            duration: 3000
			            });
                    }
	            })
		            .catch(error => {
			            console.log(error)
		            })

                if (this.mode === 'final') {
                    this.$store.commit('showAlert', {
                        message: '게임이 모두 끝났습니다.\n감사합니다!',
                        buttons: []
                    })
                }
            },

            playAudio(id) {
                this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
            }
        }
    }
</script>

<style lang="scss" scoped>

    .landscape {
        display: inherit;
    }
    .portrait {
        display: none;
    }
    @media screen and (orientation:portrait) {
        .landscape {
            display: none;
        }
        .portrait {
            display: inherit;
        }

        .buttongroup {
            width: 350px;
        }

        .buttons {
            .button {
                width: 130px !important;
            }
        }

        .btn-select {
	        width: 120px !important;
        }
    }

    .strengths-wrap {
        .message {
            margin: 0 auto;
            color: #787067;
            line-height: 1.3;
            font-weight: 400;
            font-family: KBFGDisplayB;
            margin-top: 25px;

            .hint {
                font-size: 16px;
            }

            .highlight {
                font-weight: 400;
                font-family: KBFGDisplayB;
                background-color: #FEBD36;
                padding: 1px;
            }
        }

        .buttons {
            .button {
                margin: 1px 4px;
                width: 100px;
            }
        }

        .btn-select {
            display: inline-block;
        }
    }
    .button {
        display: inline-block;
    }
</style>
