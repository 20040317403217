<template>
	<v-layout class="mission-wrap" align-center justify-center>
		<v-layout ref="mission" class="mission"
		          column
		     :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
		          v-touch:prevent="{
					up: _ => { current = Math.max(0, current-1) },
					down: _ => { current = Math.min(4, current+1) },
		          }"
		>
			<transition-group name="fade" mode="in-out" duration="50" class="d-flex align-center justify-center flex-column-reverse fill-height">
				<div class="level" :key="index" v-if="current === index || current === index - 1"
				     v-for="([key,level], index) in Object.entries($gameData.missions)"
				     :style="{
								marginTop: `-${50*$gameData.scale}px`
							 }"
				>
					<v-layout class="missions">
						<div class="item"
						     v-for="([id, mission], idx) in Object.entries(level)"
							 :style="{
								width: `${189*$gameData.scale}px`,
								height: `${223*$gameData.scale}px`,
								margin: `0 ${5*$gameData.scale}px`
							 }"
						     @click="onItemClicked(mission)"
						>
							<div v-if="$userData.missionOpened[mission.level][mission.id]" class="content-wrap">
								<img v-if="mission.project" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_pjt.png" />
								<img v-else-if="mission.quiz" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_quiz.png" />
								<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_active.png" />
								<div v-if="mission.project" class="desc"
								     :style="{
										fontSize: `${23*$gameData.scale}px`,
										color: '#BC8B36',
										marginTop: `${15*$gameData.scale}px`,
								     }"
								>
									{{ mission.name }}
								</div>
								<div v-else-if="mission.quiz" class="desc"
								     :style="{
										fontSize: `${23*$gameData.scale}px`,
										color: '#BC8B36',
										marginBottom: `${15*$gameData.scale}px`,
								     }"
								     >
									{{ mission.name }}
								</div>
								<div v-else class="desc"
								     :style="{
										fontSize: `${25*$gameData.scale}px`
								     }"
								>
									{{ mission.name }}
								</div>
								<div class="completed" v-if="$userData.missionCompleted[mission.level][mission.id]">
									<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_completed.png" />
								</div>
								<div class="point"
								     :style="{
										fontSize: `${13*$gameData.scale}px`
								     }"
								>
									{{ mission.point }}p
								</div>
							</div>
							<div v-else>
								<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_mission_disabled.png" />
							</div>
						</div>
					</v-layout>
				</div>
			</transition-group>
		</v-layout>
		<v-slider class="slider"
		          v-model="current"
		          min="0"
		          max="4"
		          vertical
		          hide-details
		          height="100%"
		          track-color="#C4BCB5"
		          track-fill-color="#C4BCB5"
		          thumb-color="#ffffff"
		/>
		<v-layout class="minimap"
		          reverse column
		          align-center
		>
			<div class="level" :key="index"
			     v-for="([key,level], index) in Object.entries($gameData.missions)"
			     :style="{
			     	    marginTop: `${-3*$gameData.scale}px`
							 }"
			>
				<v-layout class="missions">
					<div class="item"
					     v-for="([id, mission], idx) in Object.entries(level)"
					     :style="{
								width: `${22*$gameData.scale}px`,
								height: `${25*$gameData.scale}px`,
								margin: `0 ${2*$gameData.scale}px`
							 }"
					>
						<div v-if="$userData.missionOpened[mission.level][mission.id]">
							<img v-if="$userData.missionCompleted[mission.level][mission.id]" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_completed.png" />
							<img v-else-if="mission.project || mission.quiz" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_pjt.png" />
							<img v-else src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_active.png" />
						</div>
						<div v-else>
							<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/mission_mini_disabled.png" />
						</div>
					</div>
				</v-layout>
			</div>
			<div class="indicator"
			     :style="{
					height: `${55*$gameData.scale}px`,
					top: `${(5-current)*11-11}px`,
			     }"
			>

			</div>
		</v-layout>
	</v-layout>
</template>

<script>
	export default {
		data() {
			return {
				current: 0,
			}
		},

		mounted() {
			this.$eventBus.$emit('NEW_MISSION', false)
            this.$eventBus.$emit('CHECK_MISSION')
		},

		methods: {
			playAudio(id) {
				setTimeout(_ => {
					this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
				})
			},
			onItemClicked(mission) {
				if (!this.$userData.missionOpened[mission.level][mission.id]) return
				if (this.$userData.missionCompleted[mission.level][mission.id]) return


				if (mission.project) {
					this.playAudio(1)
					this.$eventBus.$emit('OPEN_PROJECT', mission.project)
					this.$eventBus.$off('ON_ARRANGE')
					this.$eventBus.$once('ON_ARRANGE', point => {
						if (point) {
							this.$userData.points += point
							this.$set(this.$userData.missionCompleted[mission.level], mission.id, true)
							this.$eventBus.$emit('CLOSE_PROJECT')

							this.$eventBus.$emit('CHECK_MISSION')
						}
					})
				}

				if (mission.quiz) {
					this.playAudio(1)
					this.$eventBus.$emit('OPEN_QUIZ', mission.quiz)
					this.$eventBus.$off('ON_SUBMIT')
					this.$eventBus.$once('ON_SUBMIT', success => {
						if (success) {
							this.$userData.points += 30
						}

						this.$set(this.$userData.missionCompleted[mission.level], mission.id, true)

						this.$eventBus.$emit('CLOSE_QUIZ')

						this.$eventBus.$emit('CHECK_MISSION')
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import 'src/assets/variables';

	.mission-wrap {
		width: 100% !important;
		height: 100% !important;
		position: fixed;
		top: 0;
		left: 0;
		background-color: #DBD3CC;

		.mission {
			display: flex;
			margin: 0;
			position: relative;
			align-items: center;
			overflow-y: scroll;
		}

		.level {

			.item {
				display: flex;
				margin: 0;
				position: relative;
				align-items: center;

				img {
					width: 100%;
				}

				.content-wrap {
					display: flex;
					justify-content: center;
					align-items: center;

					&>div {
						position: absolute;
						width: 100%;
						text-align: center;
					}

					.desc {
						color: #ffffff;
						font-family: KBFGDisplayB;
						word-break: keep-all;
					}

					.completed {
						width: 70%;
					}

					.point {
						bottom: 8%;
						color: #787067;
						font-family: KBFGDisplayB;
					}
				}
			}
		}
	}
	.slider {
		position: absolute;
		right: 2%;

	}

	.minimap {
		position: absolute;
		left: 5px;
		top: 5px;
		background-color: #ffffff;
		padding: 5px;
		border-radius: 5px;
		z-index: 10000;
		pointer-events: all;

		.indicator {
			position: absolute;
			width: 100%;
			border: 2px solid #F74343;
			border-radius: 5px;
			pointer-events: none;
		}
	}
</style>
