var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"intro-main justify-center align-center",attrs:{"column":"","fill-height":""}},[(_vm.mode === 'first' && _vm.$gameData.orientation === 'landscape')?_c('div',{staticClass:"first-wrap landscape",style:({width: ((_vm.$gameData.viewWidth) + "px"), height: ((_vm.$gameData.viewHeight) + "px")})},[_c('div',{staticClass:"first-strengths",style:({
				width: ((905*_vm.$gameData.scale) + "px"),
			})},[_c('img',{staticClass:"background",attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_final_strength.png"}}),_c('div',{staticClass:"items",style:({paddingTop: ((60*_vm.$gameData.scale) + "px")})},_vm._l((_vm.strengths.first),function(item,index){return _c('div',{key:index,staticClass:"strength"},[_c('img',{style:({
							width: ((232*_vm.$gameData.scale) + "px"),
							margin: ((10*_vm.$gameData.scale) + "px")
						 }),attrs:{"src":("https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_strength" + item + ".png")}})])}),0)]),_c('div',{staticClass:"message",style:({
				marginTop: ((23*_vm.$gameData.scale) + "px"),
				fontSize: ((40*_vm.$gameData.scale) + "px")
			})},[_c('p',[_vm._v(" 게임 플레이 후 내가 생각하는 업무 강점이 바뀌었나요?"),_c('br'),_c('img',{staticStyle:{"vertical-align":"middle","margin-bottom":"4px"},attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png","width":128*_vm.$gameData.scale}}),_vm._v(" 를 눌러 "),_c('span',{staticClass:"highlight"},[_vm._v("나의 대표 강점 3가지")]),_vm._v("를 다시 선택해주세요. ")])]),_c('img',{staticClass:"btn-start",attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png","width":210*_vm.$gameData.scale},on:{"click":function($event){return _vm.move()}}})]):_vm._e(),(_vm.mode === 'first' && _vm.$gameData.orientation === 'portrait')?_c('div',{staticClass:"first-wrap portrait",style:({width: ((_vm.$gameData.viewHeight) + "px"), height: ((_vm.$gameData.viewWidth) + "px")})},[_c('div',{staticClass:"first-strengths",style:({
				width: ((635*_vm.$gameData.scale) + "px"),
			})},[_c('img',{staticClass:"background",attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/background_final_strength_portrait.png"}}),_c('div',{staticClass:"items",style:({paddingTop: ((93*_vm.$gameData.scale) + "px"), paddingLeft: ((65*_vm.$gameData.scale) + "px")})},_vm._l((_vm.strengths.first),function(item,index){return _c('div',{key:index,staticClass:"strength"},[_c('img',{style:({
							width: ((232*_vm.$gameData.scale) + "px"),
							margin: ((10*_vm.$gameData.scale) + "px")
						 }),attrs:{"src":("https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/icon_strength" + item + ".png")}})])}),0)]),_c('div',{staticClass:"message",style:({
				marginTop: ((23*_vm.$gameData.scale) + "px"),
				fontSize: ((40*_vm.$gameData.scale) + "px")
			})},[_c('p',[_vm._v(" 게임 플레이 후"),_c('br'),_vm._v("내가 생각하는 업무 강점이 바뀌었나요?"),_c('br'),_c('img',{staticStyle:{"vertical-align":"middle","margin-bottom":"4px"},attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png","width":128*_vm.$gameData.scale}}),_vm._v(" 를 눌러 "),_c('span',{staticClass:"highlight"},[_vm._v("나의 대표 강점 3가지")]),_vm._v("를"),_c('br'),_vm._v("다시 선택해주세요. ")])]),_c('img',{staticClass:"btn-start",style:({bottom: ((40*_vm.$gameData.scale) + "px")}),attrs:{"src":"https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_select_strength.png","width":210*_vm.$gameData.scale},on:{"click":function($event){return _vm.move()}}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }