<template>
    <v-app>
        <v-main class="main">
            <v-app-bar
                    dense
                    color="white"
                    flat
                    absolute
                    v-if="appBarShow"
            >
                <v-app-bar-nav-icon @click="$router.back()" small class="ml-0">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <v-toolbar-title v-text="appBarTitle" class="font-weight-bold"></v-toolbar-title>
                <v-spacer></v-spacer>
            </v-app-bar>
            <transition name="fade" class="fill-height">
                <router-view></router-view>
            </transition>
        </v-main>

        <!-- snackbar -->
        <v-snackbar
                :timeout="snackbarDuration"
                :color="snackbarColor"
                top
                v-model="showSnackbar">
            {{ snackbarMessage }}
        </v-snackbar>

        <div class="alert" :class="showAlert ? 'active' : ''" v-show="showAlert">
	        <v-layout class="alert-body" fill-height align-center justify-center>
                <div v-if="alertMatches" class="matches"
                     :style="{fontSize: `${50*$gameData.scale}px`}"
                >
                    <p>선택한 강점 중 <span class="highlight">정답은</span></p>
                    <div class="strength-wrap"
                         :style="{width: `${687*$gameData.scale}px`}"
                    >
                        <div class="strength" v-for="(strength, index) in alertMatches">
                            <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_strength${strength.strength}_${strength.matched ? 'on' : 'off'}.png`" />
                            <img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/match_${strength.matched ? 'succeed' : 'failed'}.png`" />
                        </div>
                    </div>
                </div>
		        <div v-else class="message" v-html="alertMessage"></div>
            </v-layout>
	        <div class="btn-confirm" v-if="alertButtons">
                <img v-for="(button, index) in alertButtons" :src="button.image" @click="v => {button.cb(); playAudio(1) }"
                     v-if="button.image"
                     :style="{width: `${210*$gameData.scale}px`}"
                />
                <v-btn v-for="(button, index) in alertButtons" @click="v => {button.cb(); playAudio(1)}"
                       v-if="button.text" color="primary" class="ml-2"
                       >{{button.text}}</v-btn>
            </div>
            <div class="btn-confirm" v-else>
                <img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_confirm.png" @click="v => {alertOk(); playAudio(1); }"
                     :style="{width: `${210*$gameData.scale}px`}"
                />
            </div>
        </div>

        <!-- dialog confirm -->
        <v-dialog v-show="showDialog" v-model="showDialog" :persistent="dialogType=='confirm'" absolute max-width="450px">
            <v-card>
                <v-card-title>
                    <div class="headline"><v-icon v-if="dialogIcon">{{dialogIcon}}</v-icon> {{ dialogTitle }}</div>
                </v-card-title>
                <v-card-text v-html="dialogMessage"></v-card-text>
                <v-card-actions v-if="dialogType=='confirm' || dialogType=='alert'">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click.native="dialogCancel" v-show="dialogType=='confirm'">Cancel</v-btn>
                    <v-btn color="primary" text @click.native="dialogOk">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div id="not-support-portrait" v-if="$route.path.startsWith('/play')" onclick="document.documentElement.requestFullscreen && document.documentElement.requestFullscreen()">
        </div>
        <audio-widget />
    </v-app>
</template>

<script>

    import AudioWidget from "./components/play/AudioWidget";
	export default {
		name: 'App',

		components: {

            AudioWidget,
		},

		data() {
			return {
				transitionName: '',
			}
		},
		computed: {
			showSnackbar: {
				get() {
					return this.$store.getters.showSnackbar;
				},
				set(val) {
					if(!val) this.$store.commit('hideSnackbar');
				}
			},
			snackbarMessage() {
				return this.$store.getters.snackbarMessage;
			},
			snackbarColor() {
				return this.$store.getters.snackbarColor;
			},
			snackbarDuration() {
				return this.$store.getters.snackbarDuration;
			},


			// dialog
			showDialog: {
				get() {
					return this.$store.getters.showDialog;
				},
				set(val) {
					if(!val) this.$store.commit('hideDialog');
				}
			},
			dialogType() {
				return this.$store.getters.dialogType
			},
			dialogTitle() {
				return this.$store.getters.dialogTitle
			},
			dialogMessage() {
                return this.$store.getters.dialogMessage ? this.$store.getters.dialogMessage.replace(/\n/g, '<br />') : ''
			},
			dialogIcon() {
				return this.$store.getters.dialogIcon
			},

            showAlert() {
			    return this.$store.getters.showAlert
            },
            alertMessage() {
                return (this.$store.getters.alertMessage || '').replace(/\n/g, '<br />')
                        .replace(/\{([^\}]+)\}/g, '<span><img src="$1" style="height:30px;vertical-align:middle;" /></span>')
                    .replace(/\[([^\]]+)\]/g, '<span class="highlight">$1</span>')
            },

            alertButtons() {
				return this.$store.getters.alertButtons
            },

            alertMatches() {
				return this.$store.getters.alertMatches
            },

			appBarShow() {
				return this.$store.getters.appBarShow
			},

			appBarTitle() {
				return this.$store.getters.appBarTitle
			},

            userData() {
			    return this.$userData
            }
		},

        watch: {
		    userData: {
		        deep: true,
                handler(val) {
		            const id = this.$cookie.get('id')
		        	localStorage.setItem(`userData${id}`, JSON.stringify(val))
                }
            },
        },

		beforeMount() {
			const self = this

			this.axios.interceptors.request.use(function (config) {
				const token = self.$cookie.get('token')
				if (token) {
					config.headers.token = token
				}
				return config;
			}, function (error) {
				// Do something with request error
				console.log('axios.request.error', error)
				return Promise.reject(error);
			});

			// Add a response interceptor
			this.axios.interceptors.response.use(function (response) {
				if (response.data.code === '9901' || response.data.code === '9999') {
				// 	self.$store.commit('showSnackbar',{
				// 		message: response.data.message,
				// 		color: 'error',
				// 		duration: 3000
				// 	});
					self.$router.push('/').catch(()=>{})
				}
				return response;
			}, function (error) {
                if (!error.response || error.code === 'ECONNABORTED') {
                    // timeout of 0ms exceeded
                } else {
                    self.$store.commit('showSnackbar', {
                        message: error,
                        color: 'error',
                        duration: 3000
                    });
                }
				return Promise.reject(error);
			});



			this.loadUserData()

        },

		mounted() {


		    $(document.body).on('click', this.initAudio)

            $(window).resize(_ => {
            	setTimeout(_ => {
                    let clientWidth = document.documentElement.scrollWidth
                    let clientHeight = document.documentElement.scrollHeight
                    // let clientWidth = document.documentElement.clientWidth
                    // let clientHeight = document.documentElement.clientHeight
                    // let clientWidth = screen.width
                    // let clientHeight = screen.height

                    if (clientHeight > clientWidth) {
                        clientWidth = document.documentElement.scrollHeight
                        clientHeight = document.documentElement.scrollWidth

                        this.$gameData.orientation = 'portrait'
                    } else {
                        this.$gameData.orientation = 'landscape'
                    }

                    const screenRatio = clientHeight / clientWidth
                    const imageRatio = 720 / 1280

                    let width, height
                    if (screenRatio >= imageRatio) {
                        width = clientWidth
                        height = clientWidth * (720 / 1280)
                    } else {
                        width = clientHeight * (1280 / 720)
                        height = clientHeight
                    }

                    this.$gameData.viewWidth = width
                    this.$gameData.viewHeight = height
                    this.$gameData.scale = height / 720

                })
            }).resize()
		},
		methods: {

		    initAudio() {
		    	this.$eventBus.$emit('INIT_AUDIO')

                $(document.body).off('click', this.initAudio)
            },

            async loadUserData() {

                const id = this.$cookie.get('id')
                const localUserData = localStorage.getItem(`userData${id}`)
                const response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/trainee/userdata`)

                let userData
                let serverUserData
                if (response.data.result) {
                    serverUserData = response.data.result
                }


                // console.log(localUserData)
                if (localUserData) {
                    userData = JSON.parse(localUserData)
                }

                // 서버 데이터 복원
                if (serverUserData) {
                    userData = serverUserData
                }

                if (userData) {
                    Object.keys(userData).forEach(key => {
                        this.$set(this.$userData, key, userData[key])
                    })
                    localStorage.setItem(`userData${id}`, JSON.stringify(this.$userData))
                }

                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/points/saved`).then(response => {
                    const point = response.data.result
                    if (point) {
                        this.$userData.points += point
                        this.$userData.adminPoints += point
                    }
                })
            },



			dialogOk() {
				this.$store.commit('dialogOk');
			},
			dialogCancel() {
				this.$store.commit('dialogCancel');
			},

            alertOk() {
			    this.$store.commit('alertOk')
            },

            playAudio(id) {
                this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
            }
		}

	};
</script>

<style lang="scss" scoped>
	.v-application {
        font-family: KBFGDisplayM,serif !important;
    }
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
	.alert {
        position: fixed;
        top: 0; bottom: 0;
		left: 0; right: 0;
        z-index: 1000;

        background: #191818;
        padding: 3%;
        opacity: 0;
        transition: all 0.3s ease-in;

        &.active {
            opacity: 1;
	        animation: fadeIn 0.2s;
        }


        .alert-body {
	        border: 5px solid #ffffff;
            border-radius: 5px;
            background-color: #787067;

            .matches {
                p {
                    color: #ffffff;
                    text-align: center;
                }
            }

            .strength-wrap {
                display: flex;
                padding-bottom: 14%;

                .strength {
                    padding: 2px;
                    line-height: 1;

	                img {
                        width: 100%;
                        max-width: 216px;
                    }
                }
            }

            .message {
                font-size: 20px;
                font-weight: 400;
                font-family: KBFGDisplayB;
                line-height: 1.75;
                text-align: center;
                color: #ffffff;
                padding-bottom: 7%;

                .highlight {
                    color: #febd36;
                }
            }
        }

        .btn-confirm {
	        position: fixed;
            bottom: 14%;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {

                &+img {
                    margin-left: 5%;
                }
            }
        }
    }
</style>

<style>
    .main { background-color: #DBD3CC !important;}
    .kb-color {
        color: #cea427 !important;
        caret-color: #cea427 !important;
    }
    .kb-primary-color {
        color: #ffffff !important;
        caret-color: #ffffff !important;
        background-color: #fdd835 !important;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }
    .fade-enter-active {
        transition-delay:
                .25s;
    }
    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }

    .alert-body .highlight {
        color: #febd36;
    }

    .preloader { width: 0; height: 0; overflow: hidden; position: fixed; left: 0; visibility: hidden}


    #not-support-portrait {
        display: none;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        z-index: 10000;
        background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/message_portrait.png);
        background-size: cover;
        background-position: center;
    }

    @media screen and (orientation:portrait) {
        /*.main {*/
        /*    display: none !important;*/
        /*}*/
        #not-support-portrait {
            display: block;
        }
    }
     .v-slider--vertical {
         height: 50vh !important;
     }
    .v-slider--vertical .v-slider__track-container {
        width: 10px !important;
    }
    .v-slider--vertical .v-slider__track-background, .v-slider--vertical .v-slider__track-fill {
        border-radius: 5px;
    }
    .v-slider__thumb {
        height: 50px !important;
        border-radius: 5px !important;
    }

    .v-slider__track-background {
        top: -20px !important;
    }
    .v-slider__track-fill {
        bottom: -20px !important;
    }
    .v-slider__thumb:before {
        display: none !important;
    }
</style>

