<template>
	<v-layout column class="qr-wrap justify-center align-center text-center" fill-height>
		<div class="qr-reader"
			 :style="{width: `${$gameData.viewWidth}px`, height: `${$gameData.viewHeight}px`}"
		>
			<div v-if="mode === 'qr'" class="qr-box" :style="{
				width: `${376*$gameData.scale}px`,
				height: `${377*$gameData.scale}px`,
				top: `${207*$gameData.scale}px`,
			}">
				<qrcode-stream @decode="onDecode" v-on:init="onInit"></qrcode-stream>
			</div>
			<div v-if="mode === 'qr'" class="qr-bg">
			</div>

			<v-layout v-if="mode === 'code'" class="code-form" column align-center justify-center>
				<div>
					<div class="code-message">
						강점 및 팀원 코드를 입력해주세요.
					</div>
					<v-layout>
						<v-spacer></v-spacer>
						<v-text-field class="code" color="#787067" v-model="code" label="코드" clearable />
						<v-spacer></v-spacer>
					</v-layout>
					<div class="code-button">
						<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_confirm.png"
							 :style="{width: `${210*$gameData.scale}px`}"
							 @click="submitCode"
						/>
						<img src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_qrcode.png"
							 class="ml-2"
							 :style="{width: `${210*$gameData.scale}px`}"
							 @click="mode = 'qr'"
						/>
					</div>
				</div>
			</v-layout>
		</div>
		<img class="btn-close" src="https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/btn_close.png" @click="close()" />
	</v-layout>
</template>

<script>
	export default {
		data() {
			return {
				mode: '',
				code : '',
			}
		},

		props: [
			'imageNo',
			'type'
		],

		beforeMount() {
		},

		mounted() {
			// this.mode = 'qr'
            this.mode = 'code'
		},


		methods: {
			submitCode() {
				if (!this.code) {
					this.$store.commit('showSnackbar', {
						message: '코드를 입력하세요.',
						color: 'primary',
						duration: 3000
					})

					return
				}

				const code = this.code

				let strengthNum, characterNum

				if (this.type === 'strength') {
					this.$gameData.strengths.forEach(strength => {
						if (code.toUpperCase() === strength.code) {
							strengthNum = strength.image_num
						}
					})

					if (strengthNum) {
						this.$eventBus.$emit('ON_SELECT_QR_STRENGTH', strengthNum)
						this.$eventBus.$emit('CLOSE_QR_READER')
					} else {

						this.code = ''
						this.$store.commit('showSnackbar', {
							message: '잘못된 코드입니다. 다시 시도해주세요.',
							color: 'primary',
							duration: 3000
						})
					}
				} else if (this.type === 'character') {
					this.$gameData.characters.forEach(character => {
						if (code.toUpperCase() === character.code) {
							characterNum = character.id
						}
					})

					if (characterNum) {
						this.$eventBus.$emit('ON_SELECT_CHARACTER', characterNum)
						this.$eventBus.$emit('CLOSE_QR_READER')
					} else {

						this.code = ''
						this.$store.commit('showSnackbar', {
							message: '잘못된 코드입니다. 다시 시도해주세요.',
							color: 'primary',
							duration: 3000
						})
					}
				}
			},
			onDecode(code) {
				this.mode = ''

				let strengthNum, characterNum
				if (this.type === 'strength') {
					this.$gameData.strengths.forEach(strength => {
						if (code === strength.code) {
							strengthNum = strength.image_num
						}
					})

					if (strengthNum) {
						this.$eventBus.$emit('ON_SELECT_QR_STRENGTH', strengthNum)
						this.$eventBus.$emit('CLOSE_QR_READER')
					} else {

						this.$store.commit('showSnackbar', {
							message: '잘못된 QR코드입니다. 다시 시도해주세요.',
							color: 'primary',
							duration: 3000
						})
						this.$eventBus.$emit('CLOSE_QR_READER')
					}
				} else if (this.type === 'character') {
					this.$gameData.characters.forEach(character => {
						if (code === character.code) {
							characterNum = character.id
						}
					})


					if (characterNum) {
						this.$eventBus.$emit('ON_SELECT_CHARACTER', characterNum)
						this.$eventBus.$emit('CLOSE_QR_READER')
					} else {

						this.$store.commit('showSnackbar', {
							message: '잘못된 QR코드입니다. 다시 시도해주세요.',
							color: 'primary',
							duration: 3000
						})
						this.$eventBus.$emit('CLOSE_QR_READER')
					}
				}
			},
			onInit(promise) {

				const self = this

				promise.then(d => {
					// console.log(d)
				})
						.catch(e => {
							// this.$eventBus.$emit('CLOSE_QR_READER')

							// if (process.env.VUE_APP_MODE === 'dev') {
							// 	if (this.type === 'strength') {
							// 		this.$eventBus.$emit('OPEN_STRENGTH_SELECT', this.imageNo)
							// 	} else if (this.type === 'character') {
							// 		this.$eventBus.$emit('ON_SELECT_CHARACTER', 3)
							// 	}
							// }

							self.mode = 'code'

							// $('.qr-bg, .qr-box').hide();
							// $('.code-form').css('display', 'flex')
						})
			},
			playAudio(id) {
				this.$store.commit('setUserAction', id + '|' + Math.floor((Math.random() * 900) + 100))
			},

			close() {
				this.mode = ''
				this.$eventBus.$emit('CLOSE_QR_READER')
			}
		}
	}
</script>

<style lang="scss" scoped>

	.qr-wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		background: linear-gradient(90deg, rgba(219, 211, 204, 1) 20%, rgba(0,0,0,0) 21%, rgba(0,0,0,0) 79%, rgba(219, 211, 204, 1) 80%);
	}

	.qr-reader {
		position: absolute;
		z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qr-box {
		position: absolute;
	}

	.qr-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/kbcsi/qr_background.png);
		background-size: cover;
		background-position: center;
		z-index: 200;
	}

	.code-form {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(219, 211, 204, 1);
		font-size: calc(40*(100vmin/720));


		color: #787067;
		line-height: 1.3;
		font-weight: 400;
		font-family: KBFGDisplayB, sans-serif;

		.code-message {
			margin-bottom: 5%;
		}

		.code {
			width: calc(200*(100vmin/720));
		}
	}

	.btn-close {
		position: absolute;
		z-index: 1001;
		right: 5vh;
		top: 5vh;
		width: 30px;
	}
</style>
